import React, { useRef, useState, useEffect } from "react";
import L from 'leaflet';
import axios from "axios";
import 'leaflet/dist/leaflet.css';
import 'leaflet-polylinedecorator';

import customIconImage from '../img/history.png';
import historyIcon from '../img/history.png'
import markerCustom from '../img/Spotlight.png'


import { useNavigate } from 'react-router-dom'; // Impor useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons';
import '../css/tailwind.css'
// import lottie from 'lottie-web';
// import lottieMarker from '../img/marker.json'

const LihatRute = () => {


    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    const [overlayVisible, setOverlayVisible] = useState(false);

    const [shareModalOpen, setShareModalOpen] = useState(false)
    const [shareDuration, setShareDuration] = useState(60)
    const [customDuration, setCustomDuration] = useState('');
    const [isCustom, setIsCustom] = useState(false);
    const [customDurationUnit, setCustomDurationUnit] = useState('menit'); // Default to minutes
    const [lastSharedTime, setLastSharedTime] = useState(() => {
        return localStorage.getItem('lastSharedTime') || 0;
    });
    const [shareLink, setShareLink] = useState('');
    const [expiredAt, setExpiredAt] = useState('');
    const [copySuccess, setCopySuccess] = useState(false);
    const [isExpired, setIsExpired] = useState(false)

    const [dataRuteTerpilih, setDataRuteTerpilih] = useState(null);
    const [spotlightMarker, setSpotlightMarker] = useState(null); // State untuk spotlight marker
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [routeMarkers, setRouteMarkers] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [modalOpen, setModalOpen] = useState(false);

    const [namaTempat, setNamaTempat] = useState('');
    const [isLoading, setIsLoading] = useState(false); // New state for loading animation


    const handleOpen = (lat, lng) => {
        setSelectedLocation({ lat, lng });  // Simpan lokasi ke state
        setModalOpen(true);
        console.log('terklik cuy')

        console.log(`Loading Street View for ${lat}, ${lng}`);
        setOverlayVisible(true);



    };



    const handleClose = () => setModalOpen(false);

    const mapRef = useRef(null);
    const navigate = useNavigate(); // Buat fungsi navigate

    const createSpotlightMarker = (latLng, rute, index) => {
        const icon = L.icon({
            iconUrl: historyIcon,
            iconSize: [8, 8],
            iconAnchor: [-100, 50]
        });

        const marker = L.marker(latLng, { icon: icon }).addTo(mapRef.current);
        let popupContent = `
                <b>Latitude:</b> ${rute.latitude}<br>
                <b>Longitude:</b> ${rute.longitude}<br>
                <b>Waktu:</b> ${rute.waktu}<br>
                <br> Nama Tempat: ${namaTempat}<br>
                <button id="streetViewBtn-${index}" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Street View
                </button>
        `;
        marker.bindPopup(popupContent);


        marker.on('popupopen', () => {
            fetchPlaceName(rute.latitude, rute.longitude, marker, index, rute)
                .then((address) => {
                    console.log('alamat dari marker', address)

                    // Sekarang `address` diambil dari hasil fetchPlaceName
                    updatePopupContent(marker, index, rute, address);
                });
            const streetViewButton = document.getElementById(`streetViewBtn-${index}`);
            console.log(streetViewButton)
            if (streetViewButton) {
                streetViewButton.addEventListener('click', () => {
                    console.log(`Street View button clicked for index ${index} at Latitude: ${rute.latitude}, Longitude: ${rute.longitude}`);
                    handleOpen(rute.latitude, rute.longitude);
                });
            }
        });

        marker.addTo(mapRef.current)
        // Tambahkan event listener untuk klik marker
        marker.on('click', () => handleMarkerClick(index));

        return marker;
    };
    const handleStreetViewClick = (lat, lng) => {

        navigate('/street-view', { state: { lat, lng } });

        console.log(`Loading Street View for ${lat}, ${lng}`);


    };


    const handleMarkerClick = (index) => {
        setSelectedIndex(index);
        setSelectedLocation({
            lat: dataRuteTerpilih[index].latitude,
            lng: dataRuteTerpilih[index].longitude
        });
    };


    const handlePrevious = () => {
        if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
            setSelectedLocation({
                lat: dataRuteTerpilih[selectedIndex - 1].latitude,
                lng: dataRuteTerpilih[selectedIndex - 1].longitude
            });
            updateSpotlightMarker(selectedIndex - 1);
        }
    };

    const handleNext = () => {
        if (selectedIndex < dataRuteTerpilih.length - 1) {
            setSelectedIndex(selectedIndex + 1);
            updateSpotlightMarker(selectedIndex + 1);
            setSelectedLocation({
                lat: dataRuteTerpilih[selectedIndex + 1].latitude,
                lng: dataRuteTerpilih[selectedIndex + 1].longitude
            });
        }
    };

    const updateSpotlightMarker = (index) => {
        console.log('ini indek terpilih dengan button ', index)

        const selectedLatLng = [dataRuteTerpilih[index].latitude, dataRuteTerpilih[index].longitude];
        mapRef.current.panTo(selectedLatLng);
        if (spotlightMarker) {
            spotlightMarker.setLatLng(selectedLatLng);
        }
        routeMarkers[index].openPopup(); // Open popup of the selected marker
    };

    // Include marker, index, and rute parameters
    const fetchPlaceName = async (lat, lng, marker, index, rute) => {
        console.log('Starting to fetch place name');
        setIsLoading(true);
        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBxLVfBbTWBXZGb_-fM3WtOejDq4V8NKhA`);
            console.log(response.data)
            const address = response.data.results.length > 0 ? response.data.results[0].formatted_address : "Nama Tempat Tidak Ditemukan";
            setNamaTempat(address);
            return address; // Kembalikan alamat yang didapat untuk digunakan di .then()
        } catch (error) {
            console.error("Error fetching address", error);
            return "Error fetching address"; // Kembalikan ini sebagai address
        } finally {
            setIsLoading(false);
        }
    };

    const updatePopupContent = (marker, index, rute, address) => {
        const newPopupContent = `
            <b>Latitude:</b> ${rute.latitude}<br>
            <b>Longitude:</b> ${rute.longitude}<br>
            <b>Time:</b> ${rute.waktu}<br>
            <br> Place Name: ${address}<br>
            <button id="streetViewBtn-${index}" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Street View
            </button>
        `;
        marker.addTo(mapRef.current)
        marker.getPopup().setContent(newPopupContent);
        marker.openPopup();

        // Set event listener after updating the popup content
        const streetViewButton = document.getElementById(`streetViewBtn-${index}`);
        if (streetViewButton) {
            console.log('street view dari fnction update popup content');
            streetViewButton.addEventListener('click', () => {
                console.log(`Street View button clicked for index ${index} at Latitude: ${rute.latitude}, Longitude: ${rute.longitude}`);
                handleStreetViewClick(rute.latitude, rute.longitude);
            });
        }
    };



    // onclick="console.log('Street View bu')"
    useEffect(() => {
        if (spotlightMarker && dataRuteTerpilih && Array.isArray(dataRuteTerpilih) && dataRuteTerpilih.length > selectedIndex) {
            const newRute = dataRuteTerpilih[selectedIndex];
            if (newRute) {  // Check if the newRute is not undefined
                const newLatLng = [newRute.latitude, newRute.longitude];
                spotlightMarker.setLatLng(newLatLng);
                console.log(newRute);
                spotlightMarker.setPopupContent(`<b>Latitude:</b> ${newRute.latitude}<br><b>Longitude:</b> ${newRute.longitude}<br><b>Waktu:</b> ${newRute.waktu}<br> Nama Tempat: ${namaTempat}`);
            }
        }
        else {
            console.warn("No valid route data available at the selected index");
            // Handle cases where there is no data (e.g., show an error, or hide the marker)
        }

    }, [selectedIndex, spotlightMarker, dataRuteTerpilih]);


    const handleShareRute = async () => {
        if (!isDurationValid()) {
            alert('Please select a valid duration before sharing the route.');
            return;
        }

        let duration;
        if (isCustom) {
            duration = customDurationUnit === 'jam' ? parseInt(customDuration) * 60 : parseInt(customDuration);
        } else {
            duration = shareDuration;
        }

        try {
            const response = await axios.post('https://api.locator.my.id/share-route.php', {
                routeId: localStorage.getItem('idRuteTerpilih'),
                duration: duration
            });

            if (response.data.success) {
                // Sukses membuat link baru
                setIsExpired(false)
                setExpiredAt(response.data.newExpiresAt);
                setShareLink(response.data.shareLink);
            } else {
                // Server mencegah pembuatan link baru karena belum expired
                alert(response.data.message); // Pesan error dari server
            }
        } catch (error) {
            alert('An error occurred while sharing the route.');
            console.error('Error sharing route:', error);
        } finally {
            setShareModalOpen(false);
        }
    };



    const [activeDuration, setActiveDuration] = useState(null);
    const handleDurationSelect = (duration) => {
        setIsCustom(false); // Ensuring that custom duration input is disabled when a preset is selected
        setShareDuration(duration); // Setting the share duration state
        setActiveDuration(duration); // Updating the active duration for UI feedback
    };

    const isActive = (duration) => {
        return activeDuration === duration ? "bg-blue-200" : "";
    }
    const handleCustomDuration = (e) => {
        setCustomDuration(e.target.value);
        setIsCustom(true);
    };

    const isDurationValid = () => {
        if (isCustom) {
            // Check if custom duration is a number and greater than zero
            return customDuration > 0;
        }
        // Check if a preset duration is selected
        return shareDuration > 0;
    };
    const handleOpenShareModal = () => {
        setShareModalOpen(true);
        const map = document.getElementById('mapLihatRute');
        map.classList.add('map-lihat-rute')
    }
    const handleCloseShareModal = () => {
        setShareModalOpen(false);
        const map = document.getElementById('mapLihatRute');
        map.classList.remove('map-lihat-rute')
    }
    const selectDuration = (minutes) => {
        setShareDuration(minutes);
        setActiveDuration(minutes); // Set active state
    }

    const copyToClipboard = async (text) => {
        navigator.clipboard.writeText(text).then(() => {
            if (text !== '') {
                setCopySuccess(true);
                setTimeout(() => setCopySuccess(false), 3000);  // Reset after 3 seconds
            }
        }, () => {
            alert('Failed to copy the link.');
        });
    };


    useEffect(() => {
        const ambilDataRute = async () => {
            try {
                const routeId = localStorage.getItem('idRuteTerpilih');
                const response = await axios.get(`https://api.locator.my.id/get-rute.php?id=${routeId}`);
                const viewRute = JSON.parse(response.data.data_rute);
                const namaRute = response.data.nama_rute
                localStorage.setItem('nama_rute_terpilih', namaRute)
                setDataRuteTerpilih(viewRute);
            } catch (error) {
                console.error('Error fetching route details:', error);
            }
        };

        ambilDataRute();
    }, []);

    useEffect(() => {
        if (dataRuteTerpilih && Array.isArray(dataRuteTerpilih) && dataRuteTerpilih.length > 0) {
            if (!mapRef.current) {
                mapRef.current = L.map('mapLihatRute').setView([0, 0], 13);
                L.tileLayer('http://{s}.google.com/vt?lyrs=p&x={x}&y={y}&z={z}', {
                    maxZoom: 20,
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                }).addTo(mapRef.current);


            }

            // Buat spotlight marker di posisi pertama
            // if (!spotlightMarker) {
            //     const initialLatLng = [dataRuteTerpilih[0].latitude, dataRuteTerpilih[0].longitude];
            //     const newMarker = createSpotlightMarker(initialLatLng, dataRuteTerpilih, 2);
            //     setSpotlightMarker(newMarker);
            // }
        
            const customIconImage = L.icon({
                iconUrl: markerCustom,
                iconSize: [25, 35],
                iconAnchor: [12, 35]  // Menyesuaikan anchor point
            });

         
           
            dataRuteTerpilih.forEach((rute, index) => {
                const latLng = [rute.latitude, rute.longitude];
                const popupContent = `
                <b>Latitude:</b> ${rute.latitude}<br>
                <b>Longitude:</b> ${rute.longitude}<br>
                <b>Waktu:</b> ${rute.waktu}<br>
                <br> Nama Tempat: ${namaTempat}<br>
                <button id="streetViewBtn-${index}" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onclick='document.getElementById("streetViewBtn-${index}").addEventListener("click", () => handleStreetViewClick(${rute.latitude}, ${rute.longitude}, ${index}))'>
            Street View
        </button>`;
                // const newRouteMarkers = dataRuteTerpilih.map(rute => {
                //     const latLng = [rute.latitude, rute.longitude];
                //     const marker = L.marker(latLng, { icon: customIcon }).addTo(mapRef.current);
                //     marker.bindPopup(`<b>Latitude:</b> ${rute.latitude}<br><b>Longitude:</b> ${rute.longitude}<br><b>Tanggal:</b> ${rute.date}<br><b>Waktu:</b> ${rute.time}`);
                //     return marker;
                // });
                const indikatorIcon = L.icon({
                    iconUrl: markerCustom,
                    iconSize: [25, 35],
                    iconAnchor: [12, 35]  // Menyesuaikan anchor point
                });
              

                const startPoint = dataRuteTerpilih[0]
                const endPoint = dataRuteTerpilih[dataRuteTerpilih.length - 1]


                L.marker([startPoint.latitude, startPoint.longitude], { icon: indikatorIcon }).addTo(mapRef.current).bindPopup("Mulai Perjalanan");
                L.marker([endPoint.latitude, endPoint.longitude], { icon: indikatorIcon }).addTo(mapRef.current).bindPopup("Akhir Perjalanan");

                const circle = L.circle(latLng, {
                    color: '#f5b88e',
                    fillColor: '#90EE90',
                    fillOpacity: 0,
                    radius: 0,
                }).addTo(mapRef.current);
                const circle2 = L.circle(latLng, {
                    color: 'gray',
                    fillColor: 'gray',
                    fillOpacity: 0,
                    radius: 0,
                }).addTo(mapRef.current);



                circle.bindPopup(popupContent);
                circle2.bindPopup(popupContent);
                // const historyIcons = L.icon({
                //     iconUrl: historyIcon,
                //     iconSize: [25, 35],
                //     iconAnchor: [12, 35]  // Menyesuaikan anchor point
                // });
                // const marker = createSpotlightMarker(latLng, rute, index);
                // L.marker(latLng, { icon: historyIcons }).addTo(mapRef.current);

                // routeMarkers.push(marker);



                circle.on('click', () => {
                    localStorage.setItem('latitude_street_view', rute.latitude)
                    localStorage.setItem('longitude_street_view', rute.longitude)
                    handleMarkerClick(index)
                    fetchPlaceName(rute.latitude, rute.longitude)
                });

                circle.on('popupopen', () => {
                    circle.setStyle({
                        color: 'red',
                        fillColor: 'gray',
                    });

                    fetchPlaceName(rute.latitude, rute.longitude, marker, index, rute)
                        .then((address) => {
                            console.log('alamat dari circle', address)
                            // Sekarang `address` diambil dari hasil fetchPlaceName
                            updatePopupContent(marker, index, rute, address);
                        });
                    const streetViewButton = document.getElementById(`streetViewBtn-${index}`);
                    if (streetViewButton) {
                        // Hapus semua listener lama yang mungkin ada
                        streetViewButton.onclick = null; // Clear out any old handlers
                        streetViewButton.onclick = function () {
                            console.log(`Street View button clicked for index ${index} at Latitude: ${rute.latitude}, Longitude: ${rute.longitude}`);
                            handleStreetViewClick(rute.latitude, rute.longitude);
                            handleOpen(rute.latitude, rute.longitude);
                        };
                    }
                });

                circle2.on('click', () => {
                    localStorage.setItem('latitude_street_view', rute.latitude)
                    localStorage.setItem('longitude_street_view', rute.longitude)
                    handleMarkerClick(index)
                    fetchPlaceName(rute.latitude, rute.longitude)
                });

                circle2.on('popupopen', () => {
                    circle.setStyle({
                        color: 'red',
                        fillColor: 'gray',
                    });

                    fetchPlaceName(rute.latitude, rute.longitude, marker, index, rute)
                        .then((address) => {
                            console.log('alamat dari circle', address)
                            // Sekarang `address` diambil dari hasil fetchPlaceName
                            updatePopupContent(marker, index, rute, address);
                        });
                    const streetViewButton = document.getElementById(`streetViewBtn-${index}`);
                    if (streetViewButton) {
                        // Hapus semua listener lama yang mungkin ada
                        streetViewButton.onclick = null; // Clear out any old handlers
                        streetViewButton.onclick = function () {
                            console.log(`Street View button clicked for index ${index} at Latitude: ${rute.latitude}, Longitude: ${rute.longitude}`);
                            handleStreetViewClick(rute.latitude, rute.longitude);
                            handleOpen(rute.latitude, rute.longitude);
                        };
                    }
                });



            });


            const newRouteMarkers = dataRuteTerpilih.map(rute => {
                const historyIcons = L.icon({
                    iconUrl: historyIcon,
                    iconSize: [0, 0],
                    iconAnchor: [0, 0]  // Menyesuaikan anchor point
                });
                const latLng = [rute.latitude, rute.longitude];
                const marker = L.marker(latLng, { icon: historyIcons }).addTo(mapRef.current);
                marker.bindPopup(`<b>Latitude:</b> ${rute.latitude}<br><b>Longitude:</b> ${rute.longitude}<br><b>Waktu:</b> ${rute.waktu} <br>`);
                return marker;
            });

            setRouteMarkers(newRouteMarkers);

            const latLngs = dataRuteTerpilih.map(rute => [rute.latitude, rute.longitude]);

            const polyline = L.polyline(latLngs, { color: 'blue', weight: 1 }).addTo(mapRef.current);
            mapRef.current.fitBounds(polyline.getBounds());
            L.polylineDecorator(polyline, {
                patterns: [
                    { offset: '5%', repeat: '70', symbol: L.Symbol.arrowHead({ pixelSize: 8, polygon: false, pathOptions: { stroke: true } }) }
                ]
            }).addTo(mapRef.current)
            console.log(L)
            console.log(L.Symbol)
        }


    }, [dataRuteTerpilih, spotlightMarker]);
    const checkIsPublicRute = async () => {
        // ketika true akan mengubah status backup dan di server akan menajalankan function untuk mengirim data backup itu ke database yang nantinya akan di padukan dengan data backup di locastorage
        console.log('mulai mengecek status backup');
        try {

            const response = await axios.get(`https://api.locator.my.id/get_is_public_rute.php?id=${localStorage.getItem('idRuteTerpilih')}`);
            console.log('ini respon status ispublic', response)
            if (response.data.is_public === 1) {
                // setExpiredAt(response.data.expired_at)
                setIsExpired(false)
                setExpiredAt(`Link ini dibagikan sampai ${response.data.expired_at}`)

                setShareLink(`https://locator.my.id/shared-routes/${response.data.share_code}`)
            }
            else {
                setExpiredAt(``)
                setShareLink('')
                setIsExpired(true)
            }

        } catch (error) {
            console.error('Error checking active route', error);
        }
    };
    useEffect(() => {
        checkIsPublicRute()

    }, []);

    const isDataAvailable = dataRuteTerpilih && Array.isArray(dataRuteTerpilih);

    const handleKembali = () => {
        navigate('/dashboard');
        console.log('navigating to dashboard');
    };



    return (
        <div className="flex flex-col md:flex-row min-h-screen">

            {/* Map Container */}
            <div id="mapLihatRute" className=" flex-1" style={{ height: isMobileView ? '68vh' : '100vh' }}></div>

            {/* Info Panel */}
            {isDataAvailable && (
                <div className="md:w-1/3 bg-white p-4 flex flex-col justify-between space-y-4">
                    <div>
                        <h2 className="text-xl font-semibold text-gray-900">Rute: {localStorage.getItem('nama_rute_terpilih')}</h2>
                        <div className="mt-4 flex justify-between items-center">
                            <button
                                onClick={handlePrevious}
                                disabled={selectedIndex === 0}
                                className={`text-white bg-blue-500 hover:bg-blue-600 font-medium py-2 px-4 rounded-l ${selectedIndex === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                            <span>Posisi {selectedIndex + 1} dari {dataRuteTerpilih.length}</span>
                            <button
                                onClick={handleNext}
                                disabled={selectedIndex === dataRuteTerpilih.length - 1}
                                className={`text-white bg-blue-500 hover:bg-blue-600 font-medium py-2 px-4 rounded-r ${selectedIndex === dataRuteTerpilih.length - 1 ? 'opacity-50 cursor-not-allowed' : ''}`}
                            >
                                <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        </div>
                    </div>

                    <div className="p-4 bg-white shadow rounded-lg">
                        <div className="flex items-center space-x-2 border-b border-gray-300 pb-2">
                            <input
                                type="text"
                                value={shareLink}
                                className={`flex-1 p-2 border rounded bg-gray-100 w-full ${isExpired ? 'border-2 border-red-500' : 'border-2 border-green-500'}`}
                                readOnly
                            />
                            <button
                                onClick={() => copyToClipboard(shareLink)}
                                className={`p-2 bg-zinc-500 text-white rounded hover:bg-zinc-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors`}
                            >
                                <FontAwesomeIcon icon={copySuccess ? faCheck : faCopy} /> {copySuccess ? 'Copied' : 'Copy'}
                            </button>
                        </div>
                        <p className="mt-2 text-sm text-gray-500">Click the button to copy the link to your clipboard.</p>
                        <p className="text-bold mt-2 text-sm text-gray-500">{expiredAt}</p>
                    </div>

                    <button
                        onClick={handleOpenShareModal}
                        className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Bagikan Rute
                    </button>
                    <button
                        onClick={handleKembali}
                        className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                    >
                        Kembali
                    </button>
                </div>
            )}
            {shareModalOpen && (
                <div className="fixed inset-0 z-50 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded-lg shadow-xl w-full max-w-md">
                        <h3 className="text-lg font-medium text-gray-900">Bagikan Rute</h3>
                        <div className="mt-4">
                            <p>Pilih durasi untuk berbagi rute:</p>
                            <div className="space-y-2 mt-3">
                                <button className={`w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-blue-100 rounded-md ${isActive(15)}`} onClick={() => handleDurationSelect(15)}>15 Menit</button>
                                <button className={`w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-blue-100 rounded-md ${isActive(60)}`} onClick={() => handleDurationSelect(60)}>1 Jam</button>
                                <button className={`w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-blue-100 rounded-md ${isActive(480)}`} onClick={() => handleDurationSelect(480)}>8 Jam</button>
                                <div className="flex items-center space-x-2">
                                    <input
                                        type="number"
                                        className="w-full px-4 py-2 border rounded-md text-sm"
                                        placeholder="Custom durasi..."
                                        onChange={(e) => {
                                            setCustomDuration(e.target.value);
                                            setIsCustom(true);
                                            setActiveDuration(null);
                                        }}
                                    />

                                    <select
                                        className="border rounded-md text-sm"
                                        onChange={(e) => setCustomDurationUnit(e.target.value)} // Update the unit on change
                                    >
                                        <option value="menit">Menit</option>
                                        <option value="jam">Jam</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 flex justify-between">
                            <button onClick={handleShareRute} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                                Buat Link
                            </button>
                            <button onClick={handleCloseShareModal} className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded">
                                Batal
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>



    );
};

export default LihatRute;
