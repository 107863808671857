

const Contact = () => {
    return (
        <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100 p-4">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-2xl md:text-3xl font-bold text-gray-800 text-center mb-4">
          Hubungi Kami di WhatsApp
        </h1>
        <p className="text-gray-600 text-center mb-6">
          Kami siap membantu Anda. Klik tombol di bawah ini untuk menghubungi kami melalui WhatsApp.
        </p>
        <div className="flex justify-center">
          <a
            href="https://wa.me/6285649103100" // Ganti dengan nomor WhatsApp-mu
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center bg-green-500 hover:bg-green-600 text-white text-lg font-semibold py-3 px-6 rounded-lg transition duration-300"
          >
            Hubungi Kami
          </a>
        </div>
      </div>
    </div>
    )
}
export default Contact