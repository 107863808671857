import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const location = useLocation();
    const navbarRef = useRef(null);

    useEffect(() => {
        setIsLogin(localStorage.getItem('isLoggedIn') === 'true');
        const handleScroll = () => {
            ScrollTrigger.create({
                start: "top top",
                end: "bottom bottom",
                onUpdate: (self) => {
                    if (self.direction === -1) { // Scrolling up
                        gsap.to(navbarRef.current, { y: 0, autoAlpha: 1, duration: 0.2 });
                    } else { // Scrolling down
                        gsap.to(navbarRef.current, { y: '-100%', autoAlpha: 0, duration: 0.2 });
                    }
                }
            });
        };
        handleScroll();
        return () => ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    }, []);

    return (
        <nav ref={navbarRef} className="mb-6 bg-white p-4 fixed top-0 w-full z-50 shadow-md">
            <div className="container mx-auto flex justify-between items-center">
                {/* Logo */}
                <div 
                    className="text-lg font-semibold cursor-pointer" 
                    onClick={() => window.location.href = '/'}
                >
                    <svg version="1.1" viewBox="0 300 2048 90" width="150" height="50"  xmlns="http://www.w3.org/2000/svg">
                        <path transform="translate(145,2)" d="m0 0h55l35 4 30 6 14 4 1 1v371l-15 13-10 9-13 12-16 16-7 8-8 8-7 8-12 13-7 8-12 14-11 12-9 11-12 14-11 14-12 14-11 14-10 12-5 7-1-3 16-41 15-40 18-47 5-13 1-394-5-2-14-7-19-6-25-5-30-3-51-1 2-2 27-9 40-10 35-6 27-3z" fill="#188ABD" />
                        <path transform="translate(563,355)" d="m0 0 2 1-8 17-10 19-15 27-16 26-8 12-11 16-16 21-13 16-11 12-7 8-4 4-260 1v-4l13-14 7-8 10-11 7-8 22-24 8-8 7-8 31-31 8-7 2-2 188-1 16-8 19-12 14-11 13-11 9-8z" fill="#1C1C1C" />
                        <path transform="translate(1324,62)" d="m0 0h3l-2 4-13 17-11 13-22 28-13 16 5 9 18 27 12 19 13 19 13 20v2l2-1 22-59 20-54 21-56 2-3h34l15 40 16 43 15 40 13 34 6 16v2l-13 1h-24l-2-1-11-33v-3l-64 1-12 35-18 1h-62l-4-5-32-50-9-15-7 6-7 7-5 6-1 50-32 1-4-1v-175h36l1 77 11-14 8-11 28-36 12-16zm86 46-12 36-9 26 1 3h42l-1-6-12-37-7-21z" fill="#1FA5DF" />
                        <path transform="translate(1582,360)" d="m0 0h35l1 1v73l-1 4 5-5 14-19 12-15 30-39h45l-6 8-14 17-11 14-8 10-22 28 1 4 18 27 12 19 16 24 13 20v2h3l3-10 36-97 17-46 8-20h34l5 12 17 46 15 40 16 43 12 31v3l-37 1-3-5-10-30v-2l-63 1-12 34-1 1-80 1-7-10-14-22-11-17-12-19v-2l-5 3-12 13-2 2-1 51h-36l-1-6v-131zm224 45-5 12-13 40-4 11v2h44l-6-20-14-43z" fill="#1FA5DF" />
                        <path transform="translate(1905,360)" d="m0 0h36l4 5 17 28 13 21 31 51 5 8 1-113h36v175l-35 1-3-3-13-21-17-28-12-20-15-24-11-18-1 113h-36z" fill="#1FA5DF" />
                        <path transform="translate(1566,60)" d="m0 0h14l14 2 13 5 10 6 9 8 6 9 4 11 1 5v8h-36l-3-12-7-8-11-4h-17l-10 4-5 4-3 7v8l3 6 7 6 16 7 24 8 16 8 11 8 8 9 5 10 2 9v16l-4 13-6 9-9 8-13 6-14 4-9 1h-18l-16-3-13-5-11-6-9-8-7-10-4-11-2-11v-4h36l1 1 2 11 5 8 5 4 13 4h19l10-4 6-7 1-4v-8l-3-7-5-5-14-7-28-10-16-8-10-7-7-7-6-10-3-9v-17l4-12 8-11 9-7 14-7 13-3z" fill="#20A6E0" />
                        <path transform="translate(704,63)" d="m0 0h34l5 12 16 43 25 67 16 43 3 10-16 1h-21l-3-5-10-30v-2h-64l-3 11-8 24-3 2h-32l-4-1 3-10 11-30 11-29 20-54 19-51zm16 45-12 36-9 28 1 1h43l-3-11-16-47-3-7z" fill="#20A6E0" />
                        <path transform="translate(1139,360)" d="m0 0h34l5 12 15 40 25 67 14 37 6 16v3h-38l-7-19-5-15v-2l-63 1-12 35h-39l11-30 18-49 15-40 20-54zm16 45-6 20-15 44v1h44l-3-11-17-51z" fill="#20A6E0" />
                        <path transform="translate(1465,360)" d="m0 0h34l4 9 19 51 15 40 19 51 9 24-37 1-3-3-11-33h-63l-12 35-3 1-36-1 11-30 21-57 15-40 17-46zm17 45-15 43-7 22h44l-3-11-18-54z" fill="#20A6E0" />
                        <path transform="translate(654,360)" d="m0 0h79l15 3 12 5 11 8 8 9 6 12 3 12v18l-3 12-5 10-10 11-14 8-14 4-5 1-15 1h-30l-2-1v61l-1 1h-36v-174zm36 29-1 55 1 1h17l24-1 11-4 7-8 2-5 1-10-2-11-5-8-7-6-10-3z" fill="#20A6E0" />
                        <path transform="translate(820,63)" d="m0 0h82l14 3 16 8 10 9 7 11 4 10 2 11v11l-3 15-7 13-9 9-10 6-10 4-15 3-44 1-1 61h-36zm36 29v55h40l10-3 8-7 3-5 1-4v-15l-4-9-6-7-8-4-3-1z" fill="#20A6E0" />
                        <path transform="translate(813,360)" d="m0 0h118v28l-1 1h-81v42h69l1 1v27l-69 1v46l81 1v28h-118z" fill="#20A6E0" />
                        <path transform="translate(1316,357)" d="m0 0h10l14 2 13 4 13 7 8 7 8 11 5 11 4 16v4h-36l-4-15-5-8-5-4-8-3-5-1h-14l-12 4-6 5-6 10-4 16-1 7v36l3 18 7 14 7 6 8 3 7 1h9l12-3 9-6 5-10 2-11 1-1h35l1 4-4 17-6 12-9 10-8 7-12 6-14 4-6 1h-23l-16-4-14-7-10-9-7-8-8-16-4-13-2-12v-43l4-18 5-13 7-11 9-10 11-8 11-5 12-3z" fill="#1FA5DF" />
                        <path transform="translate(952,360)" d="m0 0h36l1 1v146h77v28h-114z" fill="#20A6E0" />
                        <path transform="translate(980,63)" d="m0 0h36v146h76l1 1v28l-108 1-5-1z" fill="#20A6E0" />
                        <path transform="translate(1664,63)" d="m0 0h36l1 2-1 173h-36z" fill="#20A6E0" />
                        <path transform="translate(1116,63)" d="m0 0h36v175h-36z" fill="#20A6E0" />
                        <path transform="translate(146,61)" d="m0 0h1l1 65 1 8 1 24 1 62 1 30v65l-1 20-1 4-1 14h-1l-1 96h-1z" fill="#20A6E0" />
                        <path transform="translate(145,451)" d="m0 0 1 3-2 6-1 7-3 9-4 14-7 15-5 13-2 7-6 13-5 15-5 9-1 1v6l-1 1h4l5-5 2 1-11 13-12 16-1-3 16-41 15-40 18-47z" fill="#20A6E0" />
                        <path transform="translate(90,595)" d="m0 0" fill="#20A6E0" />
                    </svg>
                </div>

                {/* Menu Button for Mobile */}
                <button 
                    onClick={() => setIsOpen(!isOpen)} 
                    className="text-black md:hidden focus:outline-none"
                >
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </button>

                {/* Links */}
                <div className={`md:flex md:items-center ${isOpen ? "block" : "hidden"} w-full md:w-auto`}>
                    <ul className="flex flex-col md:flex-row md:space-x-8 mt-4 md:mt-0 md:ml-auto">
                        <li><Link to="/contact-person" className="block py-2 px-4 rounded hover:bg-blue-500 hover:text-white transition-colors">Laporkan</Link></li>
                        <li><Link to="/syarat-dan-ketentuan" className="block py-2 px-4 rounded hover:bg-blue-500 hover:text-white transition-colors">Syarat dan Ketentuan</Link></li>
                        <li>
                            <button 
                                className="py-2 px-4 rounded bg-blue-500 text-white hover:bg-blue-600 transition-colors mt-4 md:mt-0"
                                onClick={() => window.location.href = '/dashboard'}
                            >
                                {isLogin ? 'Dashboard' : 'Mulai Sekarang'}
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
