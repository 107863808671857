import React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';

const Footer = () => {
    return (
        <Box style={{width: '100%'}}
            component="footer"
            sx={{
                py: 6,
                px: 2,
                mt: 'auto',
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
            }}
        >       
            <Container className="footer__section" maxWidth="lg">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Company
                        </Typography>
                        <ul>
                            <li>
                                <Link target='_blank' href="/contact-person" variant="subtitle1" color="textSecondary">
                                    About Us
                                </Link>
                            </li>
                            <li>
                                <Link target='_blank' href="/contact-person" variant="subtitle1" color="textSecondary">
                                    Careers
                                </Link>
                            </li>
                            <li>
                                <Link target='_blank' href="/contact-person" variant="subtitle1" color="textSecondary">
                                    Press
                                </Link>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Support
                        </Typography>
                        <ul>
                            <li>
                                <Link target='_blank' href="/contact-person" variant="subtitle1" color="textSecondary">
                                    Help Center
                                </Link>
                            </li>
                            <li>
                                <Link target='_blank' href="/contact-person" variant="subtitle1" color="textSecondary">
                                    Safety Center
                                </Link>
                            </li>
                            <li>
                                <Link target='_blank' href="/contact-person" variant="subtitle1" color="textSecondary">
                                    Community Guidelines
                                </Link>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Legal
                        </Typography>
                        <ul>
                            <li>
                                <Link target='_blank' href="/syarat-dan-ketentuan" variant="subtitle1" color="textSecondary">
                                    Cookies Policy
                                </Link>
                            </li>
                            <li>
                                <Link target='_blank' href="/syarat-dan-ketentuan" variant="subtitle1" color="textSecondary">
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link target='_blank' href="/syarat-dan-ketentuan" variant="subtitle1" color="textSecondary">
                                    Terms of Service
                                </Link>
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="h6" gutterBottom>
                            Follow Us
                        </Typography>
                        <ul>
                            <li>
                                <Link target='_blank' href="https://www.facebook.com/rikivscode/" variant="subtitle1" color="textSecondary">
                                    Facebook
                                </Link>
                            </li>
                           
                            <li>
                                <Link target='_blank' href="https://www.instagram.com/rikinurfalakhi_/" variant="subtitle1" color="textSecondary">
                                    Instagram
                                </Link>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Typography variant="body2" color="textSecondary" align="center">
                        {'Copyright © '}
                        <Link color="inherit" target='_blank' href="https://www.instagram.com/rikinurfalakhi_/">
                            Riki Nurfalakhi
                        </Link>{' '}
                        {new Date().getFullYear()}
                        {'.'}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
