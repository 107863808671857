import React, { useRef, useState, useEffect } from "react";
import L from 'leaflet';
import axios from "axios";
import 'leaflet/dist/leaflet.css';
import 'leaflet-polylinedecorator';





import customIconImage from '../img/history.png';
import historyIcon from '../img/history.png'
import markerCustom from '../img/Spotlight.png'
import expiredImg from '../assets/images/expired.png'

import { useParams, useNavigate } from 'react-router-dom'; // Impor useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import '../css/tailwind.css'
// import lottie from 'lottie-web';
// import lottieMarker from '../img/marker.json'

const RuteShared = () => {

    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    const [overlayVisible, setOverlayVisible] = useState(false);

    // handle preview inisiasi
    const [loading, setLoading] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const [routeData, setRouteData] = useState(null);
    const { sharedCode } = useParams(); // Assuming react-router-dom is used
    
    const [isPublic, setIsPublic] = useState(false);
    const intervalRef = useRef(null); // Menyimpan referensi ke interval untuk menghentikannya nanti

    const [dataRuteTerpilih, setDataRuteTerpilih] = useState(null);
    const [spotlightMarker, setSpotlightMarker] = useState(null); // State untuk spotlight marker
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [routeMarkers, setRouteMarkers] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null)
    const [modalOpen, setModalOpen] = useState(false);

    const [namaTempat, setNamaTempat] = useState('');
    const [isLoading, setIsLoading] = useState(false); // New state for loading animation

    const handleOpen = (lat, lng) => {
        setSelectedLocation({ lat, lng });  // Simpan lokasi ke state
        setModalOpen(true);
        console.log('terklik cuy')

        console.log(`Loading Street View for ${lat}, ${lng}`);
        setOverlayVisible(true);



    };



    const handleClose = () => setModalOpen(false);

    const mapRef = useRef(null);
    const navigate = useNavigate(); // Buat fungsi navigate

    const createSpotlightMarker = (latLng, rute, index) => {
        const icon = L.icon({
            iconUrl: historyIcon,
            iconSize: [8, 8],
            iconAnchor: [-100, 50]
        });

        const marker = L.marker(latLng, { icon: icon }).addTo(mapRef.current);
        let popupContent = `
                <b>Latitude:</b> ${rute.latitude}<br>
                <b>Longitude:</b> ${rute.longitude}<br>
                <b>Waktu:</b> ${rute.waktu}<br>
                <br> Nama Tempat: ${namaTempat}<br>
                <button id="streetViewBtn-${index}" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Street View
                </button>
        `;
        marker.bindPopup(popupContent);


        marker.on('popupopen', () => {
            fetchPlaceName(rute.latitude, rute.longitude, marker, index, rute)
                .then((address) => {
                    console.log('alamat dari marker', address)

                    // Sekarang `address` diambil dari hasil fetchPlaceName
                    updatePopupContent(marker, index, rute, address);
                });
            const streetViewButton = document.getElementById(`streetViewBtn-${index}`);
            console.log(streetViewButton)
            if (streetViewButton) {
                streetViewButton.addEventListener('click', () => {
                    console.log(`Street View button clicked for index ${index} at Latitude: ${rute.latitude}, Longitude: ${rute.longitude}`);
                    handleOpen(rute.latitude, rute.longitude);
                });
            }
        });

        marker.addTo(mapRef.current)
        // Tambahkan event listener untuk klik marker
        marker.on('click', () => handleMarkerClick(index));

        return marker;
    };
    const handleStreetViewClick = (lat, lng) => {
        navigate('/street-view', { state: { lat, lng } });
        console.log(`Loading Street View for ${lat}, ${lng}`);
    };


    const handleMarkerClick = (index) => {
        if (dataRuteTerpilih && dataRuteTerpilih.length > index) {
            setSelectedIndex(index);
            setSelectedLocation({
                lat: dataRuteTerpilih[index].latitude,
                lng: dataRuteTerpilih[index].longitude
            });
        } else {
            console.error("No route data available for the selected index");
            // Optionally, update UI to reflect the absence of data
        }
      
    };


    const handlePrevious = () => {
        if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
            setSelectedLocation({
                lat: dataRuteTerpilih[selectedIndex - 1].latitude,
                lng: dataRuteTerpilih[selectedIndex - 1].longitude
            });
            updateSpotlightMarker(selectedIndex - 1);
        }
    };

    const handleNext = () => {
        if (selectedIndex < dataRuteTerpilih.length - 1) {
            setSelectedIndex(selectedIndex + 1);
            updateSpotlightMarker(selectedIndex + 1);
            setSelectedLocation({
                lat: dataRuteTerpilih[selectedIndex + 1].latitude,
                lng: dataRuteTerpilih[selectedIndex + 1].longitude
            });
        }
    };

    const updateSpotlightMarker = (index) => {
        console.log('ini indek terpilih dengan button ', index)

        const selectedLatLng = [dataRuteTerpilih[index].latitude, dataRuteTerpilih[index].longitude];
        mapRef.current.panTo(selectedLatLng);
        if (spotlightMarker) {
            spotlightMarker.setLatLng(selectedLatLng);
        }
        routeMarkers[index].openPopup(); // Open popup of the selected marker
    };

    // Include marker, index, and rute parameters
    const fetchPlaceName = async (lat, lng, marker, index, rute) => {
        console.log('Starting to fetch place name');
        setIsLoading(true);
        try {
            const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBxLVfBbTWBXZGb_-fM3WtOejDq4V8NKhA`);
            console.log(response.data)
            const address = response.data.results.length > 0 ? response.data.results[0].formatted_address : "Nama Tempat Tidak Ditemukan";
            setNamaTempat(address);
            return address; // Kembalikan alamat yang didapat untuk digunakan di .then()
        } catch (error) {
            console.error("Error fetching address", error);
            return "Error fetching address"; // Kembalikan ini sebagai address
        } finally {
            setIsLoading(false);
        }
    };

    const updatePopupContent = (marker, index, rute, address) => {
        const newPopupContent = `
            <b>Latitude:</b> ${rute.latitude}<br>
            <b>Longitude:</b> ${rute.longitude}<br>
            <b>Time:</b> ${rute.waktu}<br>
            <br> Place Name: ${address}<br>
            <button id="streetViewBtn-${index}" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Street View
            </button>
        `;
        marker.addTo(mapRef.current)
        marker.getPopup().setContent(newPopupContent);
        marker.openPopup();

        // Set event listener after updating the popup content
        const streetViewButton = document.getElementById(`streetViewBtn-${index}`);
        if (streetViewButton) {
            console.log('street view dari fnction update popup content');
            streetViewButton.addEventListener('click', () => {
                console.log(`Street View button clicked for index ${index} at Latitude: ${rute.latitude}, Longitude: ${rute.longitude}`);
                handleStreetViewClick(rute.latitude, rute.longitude);
            });
        }
    };
    useEffect(() => {
        if (!mapRef.current && document.getElementById('mapSharedRute')) {
            initializeMap();
        }
    }, [dataRuteTerpilih]);  // This ensures map initializes once the data is ready

    const initializeMap = () => {


        mapRef.current = L.map('mapSharedRute').setView([0, 0], 13);
        L.tileLayer('http://{s}.google.com/vt?lyrs=p&x={x}&y={y}&z={z}', {
            maxZoom: 20,
            subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        }).addTo(mapRef.current);



        if (dataRuteTerpilih && dataRuteTerpilih.length > 0) {
            const latLngs = dataRuteTerpilih.map(point => [point.latitude, point.longitude]);
            const polyline = L.polyline(latLngs, { color: 'red' }).addTo(mapRef.current);
            mapRef.current.fitBounds(polyline.getBounds());
        }
    };
    const checkIsPublicRute = async () => {
        try {
            console.log('check is public rute')
            const response = await axios.get(`https://api.locator.my.id/get_is_public_rute.php?id=${localStorage.getItem('idRuteTerpilih')}`);
            console.log('ini response', response)
            if (response.data.is_public === 0) {
                console.log('rute ini kedaluarsa')
                setErrorMessage('Rute telah kadaluwarsa') // Atur isPublic true jika rute masih publik
                // setDataRuteTerpilih([])
                window.location.reload()
            } 
        } catch (error) {
            
            console.error('Error checking public status of the route:', error);
        }
    };
    useEffect(() => {
        // Menjalankan fungsi checkIsPublicRute setiap 10 detik
        intervalRef.current = setInterval(checkIsPublicRute, 10000);

        // Cleanup function untuk membersihkan interval ketika komponen di-unmount
        return () => {
            clearInterval(intervalRef.current);
        };
    }, []);
    useEffect(() => {
        const fetchRouteData = async () => {
            try {
                const response = await axios.get(`https://api.locator.my.id/get-route-by-share-code.php?shareCode=${sharedCode}`);
                if (response.data.success && response.data.data_rute) {
                    setDataRuteTerpilih(JSON.parse(response.data.data_rute));
                } else {
                    setDataRuteTerpilih([]); // Ensure it's always an array
                    setErrorMessage(response.data.message || 'Rute telah kadaluwarsa.');
                }
            } catch (error) {
                console.error('Error fetching route data:', error);
                setDataRuteTerpilih([]); // Ensure it's always an array
                setErrorMessage('Failed to load route data.');
            }
        };
    
        fetchRouteData();
        
    }, [sharedCode]);
    useEffect(() => {
        console.log("dataRuteTerpilih:", dataRuteTerpilih);

    },[dataRuteTerpilih])

  
    


    const displayRoute = (routeData) => {
        if (!mapRef.current) {
            mapRef.current = L.map('mapSharedRute').setView([0, 0], 13);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(mapRef.current);
        }

        // Assuming routeData is now an array of objects with latitude and longitude properties
        const latLngs = routeData.map(point => [parseFloat(point.latitude), parseFloat(point.longitude)]);
        const polyline = L.polyline(latLngs, { color: 'red' }).addTo(mapRef.current);
        mapRef.current.fitBounds(polyline.getBounds());
    };

    // onclick="console.log('Street View bu')"
    useEffect(() => {
        if (spotlightMarker && dataRuteTerpilih && Array.isArray(dataRuteTerpilih)) {
            const newRute = dataRuteTerpilih[selectedIndex];
            const newLatLng = [newRute.latitude, newRute.longitude];
            spotlightMarker.setLatLng(newLatLng);
            console.log(newRute)
            spotlightMarker.setPopupContent(`<b>Latitude:</b> ${newRute.latitude}<br><b>Longitude:</b> ${newRute.longitude}<br><b>Waktu:</b> ${newRute.waktu}<br> Nama Tempat: ${namaTempat}`);
            // console.log('rute ini', newRute)
            console.log(spotlightMarker)
        }
    }, [selectedIndex, spotlightMarker, dataRuteTerpilih]);











    useEffect(() => {
        if (dataRuteTerpilih && Array.isArray(dataRuteTerpilih) && dataRuteTerpilih.length > 0) {
            if (!mapRef.current) {
                // This ensures the map initializes only once and only if the map container exists
                mapRef.current = L.map('mapSharedRute', {
                    center: [dataRuteTerpilih[0].latitude, dataRuteTerpilih[0].longitude],
                    zoom: 13
                });
                L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '© OpenStreetMap contributors'
                }).addTo(mapRef.current);
            }

            // Buat spotlight marker di posisi pertama
            if (!spotlightMarker) {
                const initialLatLng = [dataRuteTerpilih[0].latitude, dataRuteTerpilih[0].longitude];
                const newMarker = createSpotlightMarker(initialLatLng, dataRuteTerpilih, 2);
                setSpotlightMarker(newMarker);
            }
            const customIcon = L.icon({
                iconUrl: customIconImage,
                iconSize: [8, 8],
                iconAnchor: [-100, 50]
            });
            dataRuteTerpilih.forEach((rute, index) => {
                const latLng = [rute.latitude, rute.longitude];
                const popupContent = `
                <b>Latitude:</b> ${rute.latitude}<br>
                <b>Longitude:</b> ${rute.longitude}<br>
                <b>Waktu:</b> ${rute.waktu}<br>
                <br> Nama Tempat: ${namaTempat}<br>
                <button id="streetViewBtn-${index}" class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onclick='document.getElementById("streetViewBtn-${index}").addEventListener("click", () => handleStreetViewClick(${rute.latitude}, ${rute.longitude}, ${index}))'>
            Street View
        </button>`;
                // const newRouteMarkers = dataRuteTerpilih.map(rute => {
                //     const latLng = [rute.latitude, rute.longitude];
                //     const marker = L.marker(latLng, { icon: customIcon }).addTo(mapRef.current);
                //     marker.bindPopup(`<b>Latitude:</b> ${rute.latitude}<br><b>Longitude:</b> ${rute.longitude}<br><b>Tanggal:</b> ${rute.date}<br><b>Waktu:</b> ${rute.time}`);
                //     return marker;
                // });
                const circle = L.circle(latLng, {
                    color: '#f5b88e',
                    fillColor: '#90EE90',
                    fillOpacity: 1,
                    radius: 20,
                }).addTo(mapRef.current);
                const circle2 = L.circle(latLng, {
                    color: 'gray',
                    fillColor: 'gray',
                    fillOpacity: 1,
                    radius: 5,
                }).addTo(mapRef.current);



                circle.bindPopup(popupContent);
                circle2.bindPopup(popupContent);

                const marker = createSpotlightMarker(latLng, rute, index);
                L.marker(latLng, { icon: customIcon }).addTo(mapRef.current);

                routeMarkers.push(marker);



                circle.on('click', () => {
                    localStorage.setItem('latitude_street_view', rute.latitude)
                    localStorage.setItem('longitude_street_view', rute.longitude)
                    handleMarkerClick(index)
                    fetchPlaceName(rute.latitude, rute.longitude)
                });

                circle.on('popupopen', () => {
                    circle.setStyle({
                        color: 'red',
                        fillColor: 'gray',
                    });

                    fetchPlaceName(rute.latitude, rute.longitude, marker, index, rute)
                        .then((address) => {
                            console.log('alamat dari circle', address)
                            // Sekarang `address` diambil dari hasil fetchPlaceName
                            updatePopupContent(marker, index, rute, address);
                        });
                    const streetViewButton = document.getElementById(`streetViewBtn-${index}`);
                    if (streetViewButton) {
                        // Hapus semua listener lama yang mungkin ada
                        streetViewButton.onclick = null; // Clear out any old handlers
                        streetViewButton.onclick = function () {
                            console.log(`Street View button clicked for index ${index} at Latitude: ${rute.latitude}, Longitude: ${rute.longitude}`);
                            handleStreetViewClick(rute.latitude, rute.longitude);
                            handleOpen(rute.latitude, rute.longitude);
                        };
                    }
                });

                circle2.on('click', () => {
                    localStorage.setItem('latitude_street_view', rute.latitude)
                    localStorage.setItem('longitude_street_view', rute.longitude)
                    handleMarkerClick(index)
                    fetchPlaceName(rute.latitude, rute.longitude)
                });

                circle2.on('popupopen', () => {
                    circle.setStyle({
                        color: 'red',
                        fillColor: 'gray',
                    });

                    fetchPlaceName(rute.latitude, rute.longitude, marker, index, rute)
                        .then((address) => {
                            console.log('alamat dari circle', address)
                            // Sekarang `address` diambil dari hasil fetchPlaceName
                            updatePopupContent(marker, index, rute, address);
                        });
                    const streetViewButton = document.getElementById(`streetViewBtn-${index}`);
                    if (streetViewButton) {
                        // Hapus semua listener lama yang mungkin ada
                        streetViewButton.onclick = null; // Clear out any old handlers
                        streetViewButton.onclick = function () {
                            console.log(`Street View button clicked for index ${index} at Latitude: ${rute.latitude}, Longitude: ${rute.longitude}`);
                            handleStreetViewClick(rute.latitude, rute.longitude);
                            handleOpen(rute.latitude, rute.longitude);
                        };
                    }
                });



            });


            const newRouteMarkers = dataRuteTerpilih.map(rute => {
                const latLng = [rute.latitude, rute.longitude];
                const marker = L.marker(latLng, { icon: customIcon }).addTo(mapRef.current);
                marker.bindPopup(`<b>Latitude:</b> ${rute.latitude}<br><b>Longitude:</b> ${rute.longitude}<br><b>Waktu:</b> ${rute.waktu} <br>`);
                return marker;
            });

            setRouteMarkers(newRouteMarkers);

            const latLngs = dataRuteTerpilih.map(rute => [rute.latitude, rute.longitude]);

            const polyline = L.polyline(latLngs, { color: 'blue', weight: 1 }).addTo(mapRef.current);
            mapRef.current.fitBounds(polyline.getBounds());
            L.polylineDecorator(polyline, {
                patterns: [
                    { offset: '5%', repeat: '70', symbol: L.Symbol.arrowHead({ pixelSize: 8, polygon: false, pathOptions: { stroke: true } }) }
                ]
            }).addTo(mapRef.current)
            console.log(L)
            console.log(L.Symbol)
        }


    }, [dataRuteTerpilih, spotlightMarker]);


    const isDataAvailable = dataRuteTerpilih && Array.isArray(dataRuteTerpilih);

    const handleGolandingPage = () => {
        navigate('/');
        console.log('navigating to landing page');
    };



    return (
        <div className="flex flex-col md:flex-row min-h-screen">
            {loading ? (
                <p>Loading...</p>
            ) : errorMessage ? (
                <div className="w-100 flex items-center justify-center h-screen">
                    <div className="text-center p-6 bg-white rounded-lg shadow-lg">
                        <img
                            src={expiredImg}
                            alt="Expired"
                            className="mx-auto mb-4 w-16 h-16"
                        />
                        <h2 className="text-xl font-bold text-red-600 mb-2">{errorMessage}</h2>
                        <button
                            onClick={() => navigate('/')}
                            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                        >
                            Kembali ke Halaman Utama
                        </button>
                    </div>
                </div>
            ) : dataRuteTerpilih && dataRuteTerpilih.length > 0 ? (
                <>
                    <div id="mapSharedRute" className="flex-1" style={{ height: isMobileView ? '68vh' : '100vh' }}></div>
                    <div className="md:w-1/3 bg-white p-4 flex flex-col justify-between space-y-4">
                            <h2 className="text-xl font-semibold text-gray-900">Rute</h2>
                            <div className="mt-4 flex justify-between items-center">
                                <button
                                    onClick={() => handlePrevious()}
                                    disabled={selectedIndex === 0}
                                    className="text-white bg-blue-500 hover:bg-blue-600 font-medium py-2 px-4 rounded-l"
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} />
                                </button>
                                <span>Posisi {selectedIndex + 1} dari {dataRuteTerpilih.length}</span>
                                <button
                                    onClick={() => handleNext()}
                                    disabled={selectedIndex === dataRuteTerpilih.length - 1}
                                    className="text-white bg-blue-500 hover:bg-blue-600 font-medium py-2 px-4 rounded-r"
                                >
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </button>
                            </div>
                            <button
                                onClick={handleGolandingPage}
                                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                            >
                                Go To Homepage
                            </button>
                        </div>
                </>
            ) : (
                <p>No route data available.</p>
            )}
        </div>
    );
    
};
export default RuteShared;
