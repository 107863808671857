import React from 'react';
import { useSpring, animated } from '@react-spring/web';

const Modal = ({ isOpen, onClose, children }) => {
    const animation = useSpring({
        opacity: isOpen ? 1 : 0,
        transform: isOpen ? `translateY(0)` : `translateY(-50%)`,
        config: { tension: 300, friction: 20 },
    });

    return (
        isOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                <animated.div style={animation} className="bg-white rounded-lg shadow-lg p-6 w-full max-w-lg relative">
                    <button className="absolute top-2 right-2 text-gray-700" onClick={onClose}>X</button>
                    {children}
                </animated.div>
            </div>
        )
    );
};

export default Modal;
