import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../notfoundpage/notfoundpage.css'

const NotFoundPage = () => {
    const [counter, setCounter] = useState(3); // Set initial countdown to 3
    const navigate = useNavigate();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCounter(prevCounter => prevCounter - 1);
        }, 1000); // Update the counter every second

        // Redirect when counter reaches 0
        if (counter === 0) {
            navigate('/');
        }

        // Clear interval on component unmount
        return () => clearInterval(intervalId);
    }, [counter, navigate]);

    useEffect(() => {
        const originalTitle = document.title;
        document.title = "404 - Halaman Tidak Ditemukan";

        return () => {
            document.title = originalTitle;
        };
    }, []);

    return (
        <div>
            <h1>404 - Halaman Tidak Ditemukan</h1>
            <p>Redirecting to homepage in {counter}...</p>
        </div>
    );
};

export default NotFoundPage;
