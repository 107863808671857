// StreetViewPage.js
import React from 'react';
import { useEffect,useState } from 'react';
import { useLocation } from 'react-router-dom';

const StreetViewPage = () => {
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')

    const location = useLocation();


    useEffect(() => {
        if (location.state) {
            const { lat, lng } = location.state;
            console.log('Ini halaman Street View', lat, lng);
            setLatitude(lat)
            setLongitude(lng)
            const streetViewContainer = document.getElementById('streetViewContainer');
            new google.maps.StreetViewPanorama(streetViewContainer, {
                position: { lat, lng },
                pov: { heading: 165, pitch: 0 },
                zoom: 1
            });
        }
    }, [location]);



    return (
        <div className="relative w-full h-screen">
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 p-4 bg-black bg-opacity-70 text-white rounded-md z-10 m-4">
            <h2 className="text-lg md:text-xl">Street View</h2>
            <p className="text-sm md:text-md">Latitude: {latitude}</p>
            <p className="text-sm md:text-md">Longitude: {longitude}</p>
        </div>
        <div id="streetViewContainer" className="w-full h-full"></div>
    </div>
    );
};


export default StreetViewPage;
