import React, { useState,useEffect, useRef, useCallback } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useLocation } from 'react-router-dom';

import bgVideo from '../../assets/video/vid.mp4';
import '../index/homeapp.css';
import Navbar from '../../components/navbar/Navbar';
import Footer from '../../components/footer/Footer';

gsap.registerPlugin(ScrollTrigger);

const HomeApp = () => {
    window.document.title = 'Beranda ~ Locator';
    const location = useLocation();
    const videoRef = useRef(null);
    const lastScrollTop = useRef(0);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768)

    const updateVideoPlayback = useCallback((direction) => {
        const video = videoRef.current;
        if (direction === 'reverse') {
            video.currentTime = Math.max(0, video.currentTime - 0.033);
        } else {
            if (video.currentTime < video.duration) {
                video.currentTime += 0.038;
            }
        }
    }, []);

    useEffect(() => {
        ScrollTrigger.create({
            trigger: '.section__content',
            start: "top top",
            end: () => isMobileView ? '+=1400' : '+=1000',
            pin: true,
            onLeave: () => videoRef.current.pause(),
            scrub: 1
        });

        const onScroll = () => {
            const st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > lastScrollTop.current) {
                updateVideoPlayback('forward');
            } else {
                updateVideoPlayback('reverse');
            }
            lastScrollTop.current = st;
        };

        window.addEventListener('scroll', onScroll);
        return () => {
            window.removeEventListener('scroll', onScroll);
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, [updateVideoPlayback]);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        // Bersihkan event listener saat komponen di-unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        isMobileView ? console.log('ini tampilan mobile') : console.log('ini bukan tampilan mobile')
    },[isMobileView])

    return (
        <section id='home__app'>
            {location.pathname === '/' && <Navbar />}
            <div className='landing__hero mt-6'>
                <h1><span>Optimalkan</span> Aktivitas Anda Dengan Sistem Pelacakan GPS yang Didukung <span>IoT.</span></h1>
                {/* <button onClick={() => window.location.href='/dashboard'} className="start-button">Get Started</button> */}
            </div>
            <div className='section__content relative w-full h-screen'>
                {/* Video Background */}
                <div className='video__container absolute top-0 left-0 w-full h-full'>
                    <video className='w-full h-full object-cover' ref={videoRef} muted playsInline loop>
                        <source src={bgVideo} type="video/mp4" />
                    </video>
                </div>

                {/* Text Overlay */}
                <div className='text__container relative z-10 bg-black bg-opacity-50 p-6 rounded-lg'>
                    <h1 id='text-first' className='my-text text-3xl md:text-4xl lg:text-5xl font-bold text-white'>
                        Jelajahi dunia dengan <span className="text-blue-500">nyaman.</span>
                    </h1>
                    <h1 id='text-second' className='my-text text-3xl md:text-4xl lg:text-5xl font-bold text-white mt-4'>
                        Kelola akses <span className="text-blue-500">pelacakan</span> dengan mudah.
                    </h1>
                    <h1 id='text-third' className='my-text text-3xl md:text-4xl lg:text-5xl font-bold text-white mt-4'>
                        Kepuasan anda <span className="text-blue-500">prioritas</span> kami.
                    </h1>
                </div>
            </div>
            <Footer />
        </section>
    );
};

export default HomeApp;
