import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import axios from 'axios';
import MarkerIcon from '../img/Spotlight.png'

const icon = new L.Icon({
    iconUrl: MarkerIcon,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34]
});


const UpdateMarker = ({ coords }) => {
    const map = useMap();
    map.flyTo([coords.latitude, coords.longitude], map.getZoom());
    return <Marker position={[coords.latitude, coords.longitude]} icon={icon} />;
};


const LacakNormal = () => {
    const [deviceTime, setDeviceTime] = useState('')
    const navigate = useNavigate()
    const [coords, setCoords] = useState({
        latitude: -7.51943404197867,
        longitude: 112.23332830934353,
        waktu: ''
    });

    useEffect(() => {
        const fetchData = async () => {
            const result = await axios('https://rest-api-4b0c2-default-rtdb.firebaseio.com/coords.json');
            setCoords(result.data);
            setDeviceTime(getDeviceTime())
        };

        fetchData();
        const intervalId = setInterval(fetchData, 20000); // Set interval to fetch data every 20 seconds

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []);

    const handleBack = () => {
        navigate('/dashboard');
    }
    const getDeviceTime = () => {
        var now = new Date(); // Dapatkan waktu dan tanggal saat ini
        var day = now.getDate(); // Tanggal
        var month = now.getMonth() + 1; // Bulan (dikoreksi karena getMonth() mengembalikan 0-11)
        var year = now.getFullYear(); // Tahun

        var hours = now.getHours(); // Jam
        var minutes = now.getMinutes(); // Menit
        var seconds = now.getSeconds(); // Detik

        // Format waktu dan tanggal agar selalu menampilkan dua digit
        day = day < 10 ? '0' + day : day;
        month = month < 10 ? '0' + month : month;
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        seconds = seconds < 10 ? '0' + seconds : seconds;

        // Tampilkan waktu dalam format YYYY-MM-DD HH:mm:ss
        var dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        return dateTimeString;
    }
    return (
        <div className="flex flex-col md:flex-row h-screen">
            <MapContainer center={[coords.latitude, coords.longitude]} zoom={13} className="flex-1">
                <TileLayer
                    url="http://{s}.google.com/vt?lyrs=m&x={x}&y={y}&z={z}"
                    maxZoom={20}
                    subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                />
                <UpdateMarker coords={coords} />
            </MapContainer>
            <div className="w-full md:w-96 lg:w-1/3 bg-white p-5 shadow-lg">
                <button
                    onClick={handleBack}
                    className="w-full bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out mb-4"
                >
                    Keluar
                </button>
                <div className="overflow-x-auto">
                    <table className="min-w-full text-sm text-left text-gray-500">
                        <tbody>
                            <tr className="bg-white border-b">
                                <td className="px-6 py-4 font-medium text-gray-900">Latitude</td>
                                <td className="px-6 py-4">{coords.latitude}</td>
                            </tr>
                            <tr className="bg-gray-50 border-b">
                                <td className="px-6 py-4 font-medium text-gray-900">Longitude</td>
                                <td className="px-6 py-4">{coords.longitude}</td>
                            </tr>
                            <tr className="bg-white border-b">
                                <td className="px-6 py-4 font-medium text-gray-900">Tanggal dan Waktu</td>
                                <td className="px-6 py-4">{deviceTime}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default LacakNormal;
