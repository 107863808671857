import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import brandAlt from '../../assets/images/brand_alt.png'
import axios from 'axios';
import { io } from 'socket.io-client';

import Spinner from '../../components/spinner/Spinner';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faFilter, faPowerOff, faCalendarDays, faXmark } from '@fortawesome/free-solid-svg-icons';
// import ParticleBackground from '../../components/ParticleBackground';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';

import '../dashboard/dashboard.css'
import Datepicker from "react-tailwindcss-datepicker";

import Modal from '../../components/modal/Modal';






import TextField from '@mui/material/TextField';


import Footer from '../../components/footer/Footer';
const Dashboard = ({ onLogin }) => {

    window.document.title = 'Dashboard ~ Locator'

    const [isAlatSiap, setIsAlatSiap] = useState(false);
    const [isRouteActive, setIsRouteActive] = useState(false);

    const [routeName, setRouteName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showRoutePopup, setShowRoutePopup] = useState(false);
    const [selectedRoute, setSelectedRoute] = useState('');
    const [showRename, setShowRename] = useState(false);
    const [renameRute, setRenameRute] = useState('');
    const [routeData, setRouteData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [rute, setRute] = useState([]);
    const [ruteExist, setRuteExist] = useState(false);
    const [isMengalihkanViewLinimasa, setIsMengalihkanViewLinimasa] = useState(false);



    const [isOpenLinimasaModal, setIsOpenLinimasaModal] = useState(false);
    const [showDateFilterLinimasa, setShowDateFilterLinimasa] = useState(false);

    const [isWarningRute, setIsWarningRute] = useState(false);
    const [statusBackup, setStatusBackup] = useState(false);
    const [isLanjutkanRuteAktif, setIsLanjutkanRuteAktif] = useState(false);



    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate();
    const [collapseTable, setCollapseTable] = useState(false)
    const [showDateFilter, setShowDateFilter] = useState(false);
    const toggleDateFilter = () => {
        setShowDateFilter(!showDateFilter);
    };
    const toggleDateFilterLinimasa = () => {
        setShowDateFilterLinimasa(!showDateFilterLinimasa);
    };
    const [dateValue, setDateValue] = useState({
        startDate: new Date(),
        endDate: new Date().setMonth(11)
    });
    const [dateValueLinimasa, setDateValueLinimasa] = useState({
        startDate: "",
        endDate: ''
    });


    const [filteredRute, setFilteredRute] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    // modal lacak pro
    const [isModalSpringOpen, setModalSpringOpen] = useState(false);
    const handleOpenSpringModal = () => {
        setModalSpringOpen(true);
    };

    const handleCloseSpringModal = () => {
        setModalSpringOpen(false);
    };


    //modal rute exist
    const [isRuteExistModal, setIsRuteExistModal] = useState(false);
    const handleOpenRuteExistModal = () => {
        setRuteExist(true)
    };

    const handleCloseRuteExistModal = () => {
        setRuteExist(falase)

    };



    //modal lihat rute
    const [isRouteModalOpen, setRouteModalOpen] = useState(false);
    const [isRenameModalOpen, setRenameModalOpen] = useState(false);
    const handleOpenRouteModal = (route) => {
        setSelectedRoute(route);
        setRouteModalOpen(true);
    };

    const handleCloseRouteModal = () => {
        setRouteModalOpen(false);
    };

    const handleOpenRenameModal = () => {
        setRenameModalOpen(true);
    };

    const handleCloseRenameModal = () => {
        setRenameModalOpen(false);
    };

    // range picker
    const handleDateValueChange = (newValue) => {
        console.log("newValue:", newValue);
        setDateValue(newValue);
    }
    // const [dateLinimasaValue, setDateLinimasaValue] = useState({
    //     startDate: new Date(),
    //     endDate: new Date().setMonth(11)
    // });



    const handleDateValueLinimasaChange = (newValue) => {
        console.log("newValueLinimasa:", newValue);
        setDateValueLinimasa(newValue);

    }

    // const handleRenameRoute = () => {
    //     console.log('Renaming route:', selectedRoute, 'to', routeName);
    //     setRenameModalOpen(false);
    //     setRouteModalOpen(false);
    // };

    // const handleContinueRoute = () => {
    //     console.log('Continuing with route:', selectedRoute);
    //     setRouteModalOpen(false);
    // };


    //modal logout
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)

    const handleOkLogout = () => {
        localStorage.removeItem('isLoggedIn');
        setTimeout(() => {
            window.location.href = '/    '
        }, 100);
    }
    const handleCancelLogout = () => {
        setIsLogoutModalOpen(false)
    }


    // useEffect(() => {
    //     const fetchRoutes = async () => {
    //         try {
    //             const response = await axios.get('https://api.locator.my.id/get-all-rute.php');
    //             setRute(response.data);
    //         } catch (error) {
    //             console.error('Error fetching route names:', error);
    //         }
    //     };

    //     fetchRoutes();
    // }, []);

    const handleRouteClick = async (routeId, routeName) => {
        // setShowRoutePopup(true);
        handleOpenRouteModal(routeName)

        localStorage.setItem('idRuteTerpilih', routeId);

        setSelectedRoute(routeName);
    };

    const handleLogout = () => {
        localStorage.removeItem('isLoggedIn');
        setTimeout(() => {
            window.location.reload();
        }, 100);
    };

    
    const handleNormalTracking = () => {
        if (isAlatSiap) {

            localStorage.setItem('mode', 'normal');
            // navigate('/tracker', { state: { trackingMode: 'normal' } });
            navigate('/lacak-normal')
            // try {
            //     const response = await axios.post('https://api.locator.my.id/api.php', { state: 1 });
            //     // if (response.data.success) {
            //     // } else {
            //     //     console.log("Failed to enable tracking");
            //     // }
            // } catch (error) {
            //     console.error('Error updating tracking status', error);
            // }
        }
        else {
            alert('Mohon mengaktifkan perangkat IoT')
        }
    };

    const handleProTracking = () => {
        // setShowModal(true);

        localStorage.setItem('mode', 'pro');
        setModalSpringOpen(true);
        // if (isAlatSiap) {


        // }
        // else {
        //     alert('Mohon mengaktifkan perangkat IoT')

        // }
    };

    const handleCancel = () => {

        setShowModal(false);
    };



    const enableRuteAktif = async () => {
        try {
            console.log('mencoba mengupdate rute aktif');
            const response = await axios.post('https://api.locator.my.id/update-rute-conditions.php', {
                column_name: 'status_rute',  // Nama kolom yang ingin diubah
                new_value: 'true'  // Nilai baru untuk kolom tersebut
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log('ini respon enable rute aktif', response)
            if (response.data.success) {
                console.log('Status rute updated successfully:', response.data.message);
            } else {
                console.error('Failed to update status rute:', response.data.message);
            }
        } catch (error) {
            console.error('Error:', error.response ? error.response.data : error.message);
        }
    }
    const handleInisiasiTemporaryDb = async () => {

        try {
            const response = await axios.post('https://api.locator.my.id/save-temporary.php', {
                id_alat: 358271,
                nama_rute: localStorage.getItem('ruteSaatIni'),
                tanggal_mulai: localStorage.getItem('tanggalMulai'),
                waktu_mulai: localStorage.getItem('waktuMulai'),
            });

            if (response.status === 200) {

                enableRuteAktif()
                console.log('Data temporary berhasil di inisiasi.');

            } else {
                console.error('Gagal menyimpan data rute.');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }



    const handleSubmit = async () => {
        localStorage.setItem('mode', 'pro');
        setIsLoading(true);
        const ruteSama = rute.some(route => route.nama_rute.toLowerCase() === routeName.toLowerCase())
        if (ruteSama) {
            setRuteExist(true)
            setIsLoading(false);

        }
        else {
            setRuteExist(false)

            try {
                const response = await axios.post('https://api.locator.my.id/api.php', { state: 1, routeName });
                if (response.data.success) {

                    localStorage.setItem('id_alat', 358271)
                    localStorage.setItem('ruteSaatIni', routeName);
                    navigate('/tracker');
                    const now = new Date();
                    const tanggalMulai = now.toISOString().split('T')[0];
                    const hours = String(now.getHours()).padStart(2, '0');
                    const minutes = String(now.getMinutes()).padStart(2, '0');
                    const seconds = String(now.getSeconds()).padStart(2, '0');
                    const waktuMulai = `${hours}:${minutes}:${seconds}`;
                    localStorage.setItem('tanggalMulai', tanggalMulai);
                    localStorage.setItem('waktuMulai', waktuMulai);
                    setShowModal(false);
                    handleInisiasiTemporaryDb()

                } else {
                    console.log("Failed to enable tracking");
                }
            }
            catch (error) {
                console.error('Error updating tracking status', error);
            }
        }
    };



    //rename
    const handleCancelRename = () => {
        setShowRename(false)
    }
    const handleSubmitRename = async () => {
        setShowRename(false)
        setShowModal(false)
        setShowRoutePopup(false)
        console.log("Rename button clicked");

        // Mengambil ID rute yang tersimpan
        const rute_id = parseInt(localStorage.getItem('idRuteTerpilih'));
        if (!rute_id) {
            console.error('No rute ID found in localStorage');
            return;
        }
        // Logika untuk mengupdate data menggunakan Axios
        try {
            const response = await axios.post('https://api.locator.my.id/ganti-nama-rute.php', {
                id_rute: rute_id,
                nama_rute_baru: renameRute
            });

            if (response.status === 200) {
                console.log('Data rute berhasil diupdate.', response.data);
                console.log('mulai mengambil rute baru');

                // Update data yang tersimpan di localStorage
                // Tindak lanjut setelah update berhasil
            } else {
                console.error('Gagal mengupdate data rute.', response.data);
                // Tindak lanjut jika update gagal
            }
        } catch (error) {
            console.error('Error:', error);
            // Penanganan error
        }


    }

    const handleCollapseTable = async () => {
        console.log('mulai mengambil data')
        const newCollapseState = !collapseTable;
        if (newCollapseState && !rute.length) { // Hanya fetch jika collapseTable akan diaktifkan dan belum ada data
            setIsLoading(true);
            try {
                const response = await axios.get('https://api.locator.my.id/get-all-rute.php');
                setRute(response.data);
                setIsLoading(false);
                console.log('sukses mengambil data rute tersimpan')
            } catch (error) {
                console.error('Error fetching route names:', error);
                setIsLoading(false);
            } finally {
                setIsLoading(false);
            }
        }
        setCollapseTable(newCollapseState);
    };



    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleOkRute = async () => {
        const routeId = localStorage.getItem('idRuteTerpilih')
        setTimeout(() => navigate('/lihat-rute'), 0); // Navigasi setelah state update

    }

    const cekStatusBackup = async () => {
        console.log('mulai cek status backup');
        try {
            const response = await axios.get(
                "https://api.locator.my.id/get-status-backup.php"
            );
            if (response.data.isActive) {
                // kondisi true

                localStorage.setItem('transferDataBackup', 'true');
            } else {
                // kondisi false
                localStorage.setItem('transferDataBackup', 'false');
            }
        } catch (error) {
            console.error("Error checking active route", error);
        }
    };
    useEffect(() => {
        const transferDataBackup = localStorage.getItem('transferDataBackup');
        if (transferDataBackup === 'true') {
            setIsWarningRute(true);
        } else {
            setIsWarningRute(false);
        }
    }, []);
    useEffect(() => {
        const intervalId = setInterval(() => {
            cekStatusBackup();
        }, 5000);

        return () => clearInterval(intervalId); // Membersihkan interval saat komponen unmount
    }, []);
    useEffect(() => {
        setFilteredRute(
            rute.filter(route =>
                Object.values(route).some(value =>
                    String(value).toLowerCase().includes(searchTerm.toLowerCase())
                )
            )
        );
        setPage(0);


    }, [searchTerm, rute]);

    useEffect(() => {
        // Fungsi untuk mengecek apakah ada rute yang sedang berjalan
        const checkActiveAlat = async () => {
            try {
                const response = await axios.get('https://api.locator.my.id/get-status-alat.php');
                if (response.data.isActive) {
                    setIsAlatSiap(true);
                } else {
                    setIsAlatSiap(false);
                }
            } catch (error) {
                console.error('Error checking active route', error);
            }
        };

        // Cek rute aktif pertama kali saat komponen dimuat
        checkActiveAlat();




        // Lakukan pengecekan secara berkala (misalnya setiap 5 detik)
        const intervalId = setInterval(checkActiveAlat, 5000); // 5000 ms = 5 detik

        // Bersihkan interval saat komponen di-unmount
        return () => clearInterval(intervalId);
    }, []);

    // const handleNavigateNearbyPage = () => {
    //     navigate('/tempat-terdekat')
    // }

    useEffect(() => {
        // Fungsi untuk mengecek apakah ada rute yang sedang berjalan
        const checkActiveRute = async () => {
            try {
                const response = await axios.get('https://api.locator.my.id/get-status-rute.php');
                if (response.data.isActive) {
                    setIsRouteActive(true);
                } else {
                    setIsRouteActive(false);
                }
            } catch (error) {
                console.error('Error checking active route', error);
            }
        };

        // Cek rute aktif pertama kali saat komponen dimuat
        checkActiveRute();




        // Lakukan pengecekan secara berkala (misalnya setiap 5 detik)
        const intervalId = setInterval(checkActiveRute, 5000); // 5000 ms = 5 detik

        // Bersihkan interval saat komponen di-unmount
        return () => clearInterval(intervalId);
    }, []);


    const handleLanjutkanRuteAktif = () => {
        console.log('sedang melanjutkan mekanisme Get Data Temporary di server');
        // Mengambil date_rute ke website dan dipadukan dengan loalstorage
        // let dataBackup = localStorage.getItem('data_backup')
        // console.log('ini isi data backup yang ada di localstorage', dataBackup)
        // localStorage.setItem('transferDataBackup', 'true')
        if (localStorage.getItem('transferDataBackup') === "true") {
            console.log('download data temporari untuk ditampilkan true');
            localStorage.setItem('btn_lanjutkan_rute_yang_belum_tersimpan', 'true')
            navigate('/tracker')

        }
        else {
            console.log('download false')
        }



        // try {
        //     console.log('mulai mengambil data hasil backup rute di database');
        //     const response = await axios.get('https://api.locator.my.id/get-all-rute.php');
        //     setRute(response.data);
        // } catch (error) {
        //     console.error('Error fetching all route :', error);
        // }

        // localStorage.setItem('dataHasilBackup', )
        // navigate('/tracker')


    }


    const handleCloseLinimasaModal = () => {
        setIsOpenLinimasaModal(false)
    }
    const handleSubmitLinimasaModal = () => {
        setIsOpenLinimasaModal(false)
    }
    const formatDate = (date) => {
        const d = new Date(date);
        return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}T${d.getHours().toString().padStart(2, '0')}:${d.getMinutes().toString().padStart(2, '0')}:${d.getSeconds().toString().padStart(2, '0')}`;
    };
    const handleLanjutkanFilterLinimasa = async () => {
        if (waktuAwalLinimasa === '' || waktuAkhirLinimasa === '') {
            alert('harap mengisi waktu')
        }
        else {
            try {
                const startDateTime = new Date(`${dateValueLinimasa.startDate}T${waktuAwalLinimasa}:00`);
                const endDateTime = new Date(`${dateValueLinimasa.endDate}T${waktuAkhirLinimasa}:00`);

                const formattedStartDate = `${startDateTime.getFullYear()}-${(startDateTime.getMonth() + 1).toString().padStart(2, '0')}-${startDateTime.getDate().toString().padStart(2, '0')} ${startDateTime.getHours().toString().padStart(2, '0')}:${startDateTime.getMinutes().toString().padStart(2, '0')}:${startDateTime.getSeconds().toString().padStart(2, '0')}`;
                const formattedEndDate = `${endDateTime.getFullYear()}-${(endDateTime.getMonth() + 1).toString().padStart(2, '0')}-${endDateTime.getDate().toString().padStart(2, '0')} ${endDateTime.getHours().toString().padStart(2, '0')}:${endDateTime.getMinutes().toString().padStart(2, '0')}:${endDateTime.getSeconds().toString().padStart(2, '0')}`;

                const postData = {
                    start_date: formattedStartDate, // "2024-09-04 14:00:00"
                    end_date: formattedEndDate       // "2024-09-04 14:13:00"
                };


                let url = `${process.env.REACT_APP_SERVER_EXPRESS}get-linimasa-user`;

                console.log('Sending data:', postData);
                const response = await axios.post(url, postData);

                if (response.data.data.length === 0) {
                    alert('Tidak ada rute di rentang tanggal tersebut')
                }
                else if(postData.startDate === 'NaN-NaN-NaN NaN:NaN:NaN' || postData.end_date ==="NaN-NaN-NaN NaN:NaN:NaN")
                {
                    alert('Harap pilih rentang tanggal')
                }
                else {
                    console.log('Response from server:', response.data.data);
                    localStorage.removeItem('data-rute-linimasa')
                    localStorage.setItem('data-rute-linimasa', JSON.stringify(response.data.data))
                    setIsMengalihkanViewLinimasa(true)
                    setTimeout(() => {
                        navigate('/linimasa-anda')
                    }, 3000);
                }
                // Additional code to handle the response...
            } catch (error) {
                console.error('Error fetching route details:', error);
            }
        }
    };

    useEffect(() => {
        console.log('Server URL:', process.env.REACT_APP_SERVER_EXPRESS);
    }, []);





    const [waktuAwalLinimasa, setWaktuAwalLinimasa] = useState('');
    const [waktuAkhirLinimasa, setWaktuAkhirLinimasa] = useState('');


    const handleChangeWaktuAwalLinimasa = (e) => {
        setWaktuAwalLinimasa(e.target.value)
        console.log('waktu awal', e.target.value)
    }


    const handleChangeWaktuAkhirLinimasa = (e) => {
        setWaktuAkhirLinimasa(e.target.value)
        console.log('waktu akhir', e.target.value)
    }


    // useEffect(() => {
    //     if(!ruteExist)
    //     {
    //         setIsLoading(false)

    //     }
    // }, [ruteExist])

    const inisialisasiGetAllRute = async () => {
        try {
            console.log('mulai mengambil data all rute');
            const response = await axios.get('https://api.locator.my.id/get-all-rute.php');
            setRute(response.data);
            localStorage.removeItem('downloadedDataRuteBackup')

        } catch (error) {
            console.error('Error fetching all route :', error);
        }
    }

    useEffect(() => {
        inisialisasiGetAllRute()
    }, [])






    return (
        <div className="w-full text-center homepage__container relative">
            <nav className="fixed top-0 w-full bg-white shadow-md z-20 py-4">
                <div className="container mx-auto flex justify-between items-center px-4">
                    <div style={{ cursor: 'pointer' }} onClick={() => window.location.href = '/'} className="brand_alt">
                        <svg version="1.1" viewBox="0 300 2048 90" width="150" height="50" xmlns="http://www.w3.org/2000/svg">
                            <path transform="translate(145,2)" d="m0 0h55l35 4 30 6 14 4 1 1v371l-15 13-10 9-13 12-16 16-7 8-8 8-7 8-12 13-7 8-12 14-11 12-9 11-12 14-11 14-12 14-11 14-10 12-5 7-1-3 16-41 15-40 18-47 5-13 1-394-5-2-14-7-19-6-25-5-30-3-51-1 2-2 27-9 40-10 35-6 27-3z" fill="#188ABD" />
                            <path transform="translate(563,355)" d="m0 0 2 1-8 17-10 19-15 27-16 26-8 12-11 16-16 21-13 16-11 12-7 8-4 4-260 1v-4l13-14 7-8 10-11 7-8 22-24 8-8 7-8 31-31 8-7 2-2 188-1 16-8 19-12 14-11 13-11 9-8z" fill="#1C1C1C" />
                            <path transform="translate(1324,62)" d="m0 0h3l-2 4-13 17-11 13-22 28-13 16 5 9 18 27 12 19 13 19 13 20v2l2-1 22-59 20-54 21-56 2-3h34l15 40 16 43 15 40 13 34 6 16v2l-13 1h-24l-2-1-11-33v-3l-64 1-12 35-18 1h-62l-4-5-32-50-9-15-7 6-7 7-5 6-1 50-32 1-4-1v-175h36l1 77 11-14 8-11 28-36 12-16zm86 46-12 36-9 26 1 3h42l-1-6-12-37-7-21z" fill="#1FA5DF" />
                            <path transform="translate(1582,360)" d="m0 0h35l1 1v73l-1 4 5-5 14-19 12-15 30-39h45l-6 8-14 17-11 14-8 10-22 28 1 4 18 27 12 19 16 24 13 20v2h3l3-10 36-97 17-46 8-20h34l5 12 17 46 15 40 16 43 12 31v3l-37 1-3-5-10-30v-2l-63 1-12 34-1 1-80 1-7-10-14-22-11-17-12-19v-2l-5 3-12 13-2 2-1 51h-36l-1-6v-131zm224 45-5 12-13 40-4 11v2h44l-6-20-14-43z" fill="#1FA5DF" />
                            <path transform="translate(1905,360)" d="m0 0h36l4 5 17 28 13 21 31 51 5 8 1-113h36v175l-35 1-3-3-13-21-17-28-12-20-15-24-11-18-1 113h-36z" fill="#1FA5DF" />
                            <path transform="translate(1566,60)" d="m0 0h14l14 2 13 5 10 6 9 8 6 9 4 11 1 5v8h-36l-3-12-7-8-11-4h-17l-10 4-5 4-3 7v8l3 6 7 6 16 7 24 8 16 8 11 8 8 9 5 10 2 9v16l-4 13-6 9-9 8-13 6-14 4-9 1h-18l-16-3-13-5-11-6-9-8-7-10-4-11-2-11v-4h36l1 1 2 11 5 8 5 4 13 4h19l10-4 6-7 1-4v-8l-3-7-5-5-14-7-28-10-16-8-10-7-7-7-6-10-3-9v-17l4-12 8-11 9-7 14-7 13-3z" fill="#20A6E0" />
                            <path transform="translate(704,63)" d="m0 0h34l5 12 16 43 25 67 16 43 3 10-16 1h-21l-3-5-10-30v-2h-64l-3 11-8 24-3 2h-32l-4-1 3-10 11-30 11-29 20-54 19-51zm16 45-12 36-9 28 1 1h43l-3-11-16-47-3-7z" fill="#20A6E0" />
                            <path transform="translate(1139,360)" d="m0 0h34l5 12 15 40 25 67 14 37 6 16v3h-38l-7-19-5-15v-2l-63 1-12 35h-39l11-30 18-49 15-40 20-54zm16 45-6 20-15 44v1h44l-3-11-17-51z" fill="#20A6E0" />
                            <path transform="translate(1465,360)" d="m0 0h34l4 9 19 51 15 40 19 51 9 24-37 1-3-3-11-33h-63l-12 35-3 1-36-1 11-30 21-57 15-40 17-46zm17 45-15 43-7 22h44l-3-11-18-54z" fill="#20A6E0" />
                            <path transform="translate(654,360)" d="m0 0h79l15 3 12 5 11 8 8 9 6 12 3 12v18l-3 12-5 10-10 11-14 8-14 4-5 1-15 1h-30l-2-1v61l-1 1h-36v-174zm36 29-1 55 1 1h17l24-1 11-4 7-8 2-5 1-10-2-11-5-8-7-6-10-3z" fill="#20A6E0" />
                            <path transform="translate(820,63)" d="m0 0h82l14 3 16 8 10 9 7 11 4 10 2 11v11l-3 15-7 13-9 9-10 6-10 4-15 3-44 1-1 61h-36zm36 29v55h40l10-3 8-7 3-5 1-4v-15l-4-9-6-7-8-4-3-1z" fill="#20A6E0" />
                            <path transform="translate(813,360)" d="m0 0h118v28l-1 1h-81v42h69l1 1v27l-69 1v46l81 1v28h-118z" fill="#20A6E0" />
                            <path transform="translate(1316,357)" d="m0 0h10l14 2 13 4 13 7 8 7 8 11 5 11 4 16v4h-36l-4-15-5-8-5-4-8-3-5-1h-14l-12 4-6 5-6 10-4 16-1 7v36l3 18 7 14 7 6 8 3 7 1h9l12-3 9-6 5-10 2-11 1-1h35l1 4-4 17-6 12-9 10-8 7-12 6-14 4-6 1h-23l-16-4-14-7-10-9-7-8-8-16-4-13-2-12v-43l4-18 5-13 7-11 9-10 11-8 11-5 12-3z" fill="#1FA5DF" />
                            <path transform="translate(952,360)" d="m0 0h36l1 1v146h77v28h-114z" fill="#20A6E0" />
                            <path transform="translate(980,63)" d="m0 0h36v146h76l1 1v28l-108 1-5-1z" fill="#20A6E0" />
                            <path transform="translate(1664,63)" d="m0 0h36l1 2-1 173h-36z" fill="#20A6E0" />
                            <path transform="translate(1116,63)" d="m0 0h36v175h-36z" fill="#20A6E0" />
                            <path transform="translate(146,61)" d="m0 0h1l1 65 1 8 1 24 1 62 1 30v65l-1 20-1 4-1 14h-1l-1 96h-1z" fill="#20A6E0" />
                            <path transform="translate(145,451)" d="m0 0 1 3-2 6-1 7-3 9-4 14-7 15-5 13-2 7-6 13-5 15-5 9-1 1v6l-1 1h4l5-5 2 1-11 13-12 16-1-3 16-41 15-40 18-47z" fill="#20A6E0" />
                            <path transform="translate(90,595)" d="m0 0" fill="#20A6E0" />
                        </svg>

                    </div>
                    <button
                        onClick={() => setIsLogoutModalOpen(true)}
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                        <FontAwesomeIcon icon={faPowerOff} /> Logout
                    </button>
                </div>
            </nav>

            <main className="container mx-auto mt-24 p-6">
                <div className="flex justify-end items-center mb-6">
                    {isAlatSiap ? (
                        <div className="flex items-center space-x-2">
                            <div className="w-4 h-4 bg-green-500 rounded-full animate-pulse"></div>
                            <span className="bg-green-500 rounded text-white font-bold py-2 px-4">Alat Sudah Siap</span>

                        </div>
                    ) : (
                        <div className="flex items-center space-x-2">
                            <div className="w-4 h-4 bg-red-500 rounded-full"></div>
                            <span className="text-red-500 font-bold">Alat Belum Siap</span>
                        </div>
                    )}
                </div>
                <div className="flex justify-end items-center mb-6">
                    {isRouteActive ? (
                        <div className="flex items-center space-x-2">
                            <div className="w-4 h-4 bg-green-500 rounded-full animate-pulse"></div>

                            <button
                                onClick={handleLanjutkanRuteAktif}
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Lanjutkan Rute Aktif
                            </button>
                        </div>
                    ) : (
                        <div className="flex items-center space-x-2">
                            <div className="w-4 h-4 bg-red-500 rounded-full"></div>
                            <span className="text-red-500 font-bold">Tidak Ada Rute Aktif</span>
                        </div>
                    )}
                </div>


                <div className="bg-white rounded-lg p-6">
                    <div className="text-center mb-12">
                        <h2 className="text-3xl font-extrabold text-zinc-900 mb-4">Petunjuk</h2>
                        <p className="text-xl text-gray-600">Silakan pilih mode pelacakan yang sesuai dengan kebutuhan Anda.</p>
                    </div>
                    <div className="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-6">
                        <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 text-center">

                            <h3 className="text-xl font-semibold text-zinc-900 mb-2">Lacak Normal</h3>
                            <p className="text-gray-600 mb-4">Gunakan mode ini untuk pelacakan standar.</p>
                            <button
                                onClick={handleNormalTracking}
                                className="bg-custom-primary hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Pilih
                            </button>
                        </div>
                        <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 text-center">
                            <h3 className="text-xl font-semibold text-zinc-900 mb-2">Lacak Pro</h3>
                            <p className="text-gray-600 mb-4">Gunakan mode ini untuk pelacakan lanjutan dengan fitur tambahan.</p>
                            <button
                                onClick={handleProTracking}
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Pilih
                            </button>
                        </div>
                    </div>
                    <div className="mb-4 grid grid-cols-1 ">


                        <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 text-center">
                            <h3 className="text-xl font-semibold text-zinc-900 mb-2">Linimasa Anda</h3>
                            <p className="text-gray-600 mb-4">Gunakan mode ini untuk melihat detail histori lanjutan.</p>
                            <button
                                onClick={() => setIsOpenLinimasaModal(true)}
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Lihat
                            </button>
                        </div>
                    </div>
                    <div className="bg-white border border-gray-200 rounded-lg shadow-md p-6 text-center w-full mt-6 lg:mt-0">
                        <h3 className="text-xl font-semibold text-zinc-900 mb-2">
                            {collapseTable ? 'Sembunyikan Rute' : 'Pengelolaan Rute'}
                        </h3>
                        <p className="text-zinc-600 mb-4">Gunakan tombol ini untuk mengelola rute yang ada.</p>
                        <button
                            onClick={handleCollapseTable}
                            className="bg-zinc-800 hover:bg-zinc-950 text-white font-bold py-2 px-4 rounded"
                            disabled={isLoading}
                        >
                            {collapseTable ? 'Sembunyikan' : 'Kelola'}
                        </button>
                        <div className="mt-8 mx-auto max-w-7xl">
                            {isLoading && <Spinner />}
                            {collapseTable && (
                                <div className="relative mt-8">
                                    <div className="sticky top-0 z-10 bg-white p-4 shadow-md">
                                        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between mb-4 space-y-4 lg:space-y-0">

                                            <TextField
                                                label="Search..."
                                                variant="outlined"
                                                fullWidth
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                className="flex-grow lg:mr-4 px-4"
                                            />
                                            <div className="flex items-center space-x-4 w-full lg:w-auto lg:flex-none">
                                                <button
                                                    onClick={toggleDateFilter}
                                                    className={`py-2 px-4 rounded ${showDateFilter ? 'bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded' : 'bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'}`}


                                                >
                                                    {!showDateFilter ? <FontAwesomeIcon icon={faCalendarDays} /> : <FontAwesomeIcon icon={faXmark} />}
                                                </button>

                                            </div>
                                            {showDateFilter && (

                                                <div className="flex p-4 shadow-md items-center space-x-4 w-full lg:w-auto lg:flex-none">
                                                    <Datepicker
                                                        value={dateValue}
                                                        onChange={handleDateValueChange}
                                                        className="flex-grow lg:w-auto"
                                                    />
                                                    <button

                                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"

                                                    >
                                                        Terapkan
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full max-w-screen-lg divide-y divide-gray-200">
                                            <thead className="bg-zinc-900">
                                                <tr>
                                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">Nomor</th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">ID Alat</th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">Nama Rute</th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">Tanggal Mulai</th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">Tanggal Selesai</th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">Waktu Mulai</th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-white">Waktu Selesai</th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white divide-y divide-gray-200">
                                                {filteredRute.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((route, index) => (
                                                    <tr key={route.id} onClick={() => handleRouteClick(route.id, route.nama_rute)} className="hover:bg-gray-100 cursor-pointer">
                                                        {/* Tampilkan nomor urut */}
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{page * rowsPerPage + index + 1}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{route.id_alat}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{route.nama_rute}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{route.tanggal_mulai}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{route.tanggal_selesai}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{route.waktu_mulai}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{route.waktu_selesai}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <TablePagination
                                            className="paginationku"
                                            rowsPerPageOptions={[5, 10, 25]}
                                            component="div"
                                            count={filteredRute.length} // Perbaiki jika Anda ingin menghitung semua item yang ada
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </div>

                                </div>
                            )}
                        </div>
                    </div>
                    {/* <div className="flex items-center space-x-2">
                        <div className="w-4 h-4 bg-green-500 rounded-full animate-pulse"></div>

                        <button
                            onClick={handleNavigateNearbyPage}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Nearby Search
                        </button>
                    </div> */}

                    {/* <div className="flex flex-wrap justify-center mt-4 gap-4">
                    <button 
                        onClick={handleNormalTracking} 
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                        Lacak Normal
                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                    </button>
                    <button 
                        onClick={handleProTracking} 
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                        Lacak Pro
                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                    </button>
                    <button 
                        onClick={handleCollapseTable} 
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
                        disabled={isLoading}
                    >
                        {collapseTable ? 'Sembunyikan' : 'Tampilkan Rute'}
                    </button>
                </div> */}
                </div>


                {/* <Modal isOpen={isRuteExistModal} onClose={handleCloseRuteExistModal}>
                    <h2 className="text-2xl font-semibold mb-4">Anda telah membuat rute ini pada...</h2>
                   
                    <div className="flex justify-end space-x-4">
                  
                        <button
                            onClick={handleCloseRuteExistModal}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            OK
                        </button>
                    </div>
                </Modal> */}

                <Modal isOpen={isOpenLinimasaModal} onClose={handleCloseLinimasaModal}>
                    <div>
                        <h2 className="text-2xl font-semibold mb-4">Informasi Linimasa</h2>

                        <div className="flex items-center space-x-4 w-full">
                            <p className="text-zinc-500 text-sm mt-2">Filter Linimasa</p>
                            <button
                                onClick={toggleDateFilterLinimasa}
                                className={`py-2 px-4 rounded ${showDateFilterLinimasa ? 'bg-red-500 hover:bg-red-700 text-white' : 'bg-blue-500 hover:bg-blue-700 text-white'} font-bold`}
                            >
                                {!showDateFilterLinimasa ? <FontAwesomeIcon icon={faCalendarDays} /> : <FontAwesomeIcon icon={faXmark} />}
                            </button>
                        </div>

                        {showDateFilterLinimasa && (
                            <div className="flex flex-col p-4 shadow-md w-full">
                                <div className="flex flex-row justify-between space-x-4 mb-4">
                                    <div className="flex-1">
                                        <label htmlFor="time-input-start" className="mb-2 text-sm font-medium text-gray-900">Waktu Awal</label>
                                        <input
                                            type="time"
                                            id="time-input-start"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            value={waktuAwalLinimasa}
                                            onChange={handleChangeWaktuAwalLinimasa}
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <label htmlFor="time-input-end" className="mb-2 text-sm font-medium text-gray-900">Waktu Akhir</label>
                                        <input
                                            type="time"
                                            id="time-input-end"
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            value={waktuAkhirLinimasa}
                                            onChange={handleChangeWaktuAkhirLinimasa}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="datepicker-linimasa" className="mb-2 text-sm font-medium text-gray-900">Tanggal Linimasa</label>
                                    <Datepicker
                                        id="datepicker-linimasa"
                                        value={dateValueLinimasa}
                                        onChange={handleDateValueLinimasaChange}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    />
                                </div>
                            </div>
                        )}

                        <div className="flex justify-end space-x-4 mt-4">
                            <button
                                onClick={() => setIsOpenLinimasaModal(false)}
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Batal
                            </button>
                            {showDateFilterLinimasa && (
                                <button
                                    onClick={handleLanjutkanFilterLinimasa}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    {isMengalihkanViewLinimasa ? "Mengalihkan..." : 'Lanjutkan'}
                                </button>
                            )}
                        </div>
                    </div>

                </Modal>
                <Modal isOpen={isModalSpringOpen} onClose={handleCloseSpringModal}>
                    {localStorage.getItem('transferDataBackup') === 'true' ? (
                        <div>
                            <h2 className="text-2xl font-semibold mb-4">Peringatan!</h2>
                            <p className="text-red-500 text-sm mt-2">
                                Ada rute yang belum tersimpan. Mohon selesaikan rute tersebut sebelum melanjutkan.
                            </p>
                            <button
                                onClick={handleCloseSpringModal}
                                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mt-4"
                            >
                                OK
                            </button>
                        </div>
                    ) : (
                        <div>
                            <h2 className="text-2xl font-semibold mb-4">Masukkan Nama Rute</h2>
                            {ruteExist && (
                                <h3 className='text-red-500 text-sm mt-2'>Rute ini telah ada</h3>
                            )}
                            <input
                                type="text"
                                className="shadow-md border rounded w-full py-2 px-3 mb-4 text-gray-700 focus:outline-none focus:shadow-outline"
                                placeholder="Nama Rute"
                                value={routeName}
                                onChange={(e) => { setRouteName(e.target.value); setRuteExist(false) }}
                            />
                            <div className="flex justify-end space-x-4">
                                <button
                                    onClick={handleCloseSpringModal}
                                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleSubmit}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    )}
                </Modal>


                <Modal isOpen={isRouteModalOpen} onClose={handleCloseRouteModal}>
                    <h2 className="text-2xl font-semibold mb-4">Lanjut Melihat Rute</h2>
                    <p>Apakah Anda ingin melanjutkan melihat rute "{selectedRoute}"?</p>
                    <div className="flex justify-between space-x-4 mt-4">
                        <button
                            onClick={handleOpenRenameModal}
                            className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Rename
                        </button>
                        <div className="flex space-x-4">
                            <button
                                onClick={handleCloseRouteModal}
                                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleOkRute}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                OK
                            </button>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={isRenameModalOpen} onClose={handleCloseRenameModal}>
                    <h2 className="text-2xl font-semibold mb-4">Rename Route</h2>
                    <input
                        type="text"
                        className="shadow-md border rounded w-full py-2 px-3 mb-4 text-gray-700 focus:outline-none focus:shadow-outline"
                        placeholder="New Route Name"
                        value={selectedRoute}
                        onChange={(e) => setRenameRute(e.target.value)}
                    />
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={handleCloseRenameModal}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleSubmitRename}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            OK
                        </button>
                    </div>
                </Modal>

                <Modal isOpen={isLogoutModalOpen} onClose={handleCancelLogout}>
                    <h2 className="text-2xl font-semibold mb-4">Apakah ingin keluar?</h2>
                    <p className="mb-4">ID perangkat yang telah login adalah: <span className="font-mono text-gray-700">{localStorage.getItem('deviceId')}</span></p>
                    <div className="flex justify-end space-x-4">
                        <button
                            onClick={handleCancelLogout}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleOkLogout}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                            OK
                        </button>
                    </div>
                </Modal>

                {showModal && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-5 rounded-lg shadow-xl w-full max-w-md">
                            <h2 className="text-xl mb-4">Masukkan Nama Rute</h2>
                            <input
                                type="text"
                                className="border p-2 rounded w-full mb-4"
                                placeholder="Nama Rute"
                                value={routeName}
                                onChange={(e) => setRouteName(e.target.value)}
                            />
                            <div className="flex justify-center space-x-4">
                                <button
                                    onClick={handleCancel}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                    disabled={isLoading}
                                >
                                    Batal
                                </button>
                                <button
                                    onClick={handleSubmit}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    disabled={isLoading}
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {showRename && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-5 rounded-lg shadow-xl w-full max-w-md">
                            <h2 className="text-xl mb-4">Masukkan Nama Rute Baru</h2>
                            <input
                                type="text"
                                className="border p-2 rounded w-full mb-4"
                                placeholder="Nama Rute Baru"
                                value={renameRute}
                                onChange={(e) => setRenameRute(e.target.value)}
                            />
                            <div className="flex justify-center space-x-4">
                                <button
                                    onClick={handleCancelRename}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                    disabled={isLoading}
                                >
                                    Batal
                                </button>
                                <button
                                    onClick={handleSubmitRename}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    disabled={isLoading}
                                >
                                    {isLoading ? <Spinner /> : 'OK'}
                                </button>
                            </div>
                        </div>
                    </div>
                )}



                {showRoutePopup && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                        <div className="bg-white p-5 rounded-lg shadow-xl w-full max-w-md">
                            <h2 className="text-xl mb-4">Lanjut Melihat Rute?</h2>
                            <p>Apakah Anda ingin melanjutkan melihat rute "{selectedRoute}"?</p>
                            <a
                                href="#"
                                onClick={() => setShowRename(true)}
                                className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                            >
                                Ubah nama
                            </a>
                            <div className="flex justify-center space-x-4 mt-4">
                                <button
                                    onClick={() => setShowRoutePopup(false)}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Batal
                                </button>
                                <button
                                    onClick={handleOkRute}
                                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </main>

            <Footer />
        </div>


    );

};

export default Dashboard;
