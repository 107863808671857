import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import '../syaratdanketentuan/syaratdanketentuan.css'

import Navbar from '../../components/navbar/Navbar';
const SyaratDanKetentuan = () => {
    window.document.title = 'Syarat dan Ketentuan ~ Locator'

    return (
        <>
            <Navbar></Navbar>
            <Container maxWidth="md" style={{ marginTop: '10rem' }}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Syarat dan Ketentuan
                </Typography>
                <Box my={2}>
                    <Typography variant="h6" component="h2">
                        1. Penerimaan Syarat
                    </Typography>
                    <Typography paragraph>
                        Dengan mengakses dan menggunakan aplikasi pelacakan berbasis IoT ini, Anda menyetujui untuk terikat oleh syarat dan ketentuan yang berlaku. Jika Anda tidak menyetujui syarat dan ketentuan ini, harap jangan menggunakan aplikasi ini.
                    </Typography>
                </Box>
                <Box my={2}>
                    <Typography variant="h6" component="h2">
                        2. Perubahan pada Syarat dan Ketentuan
                    </Typography>
                    <Typography paragraph>
                        Kami berhak untuk mengubah syarat dan ketentuan ini dari waktu ke waktu tanpa pemberitahuan sebelumnya. Anda bertanggung jawab untuk meninjau syarat dan ketentuan ini secara berkala untuk memastikan kepatuhan Anda.
                    </Typography>
                </Box>
                <Box my={2}>
                    <Typography variant="h6" component="h2">
                        3. Penggunaan Aplikasi
                    </Typography>
                    <Typography paragraph>
                        Aplikasi ini hanya boleh digunakan untuk tujuan yang sah dan sesuai dengan semua hukum dan peraturan yang berlaku.
                    </Typography>
                </Box>
                <Box my={2}>
                    <Typography variant="h6" component="h2">
                        4. Hak Kekayaan Intelektual
                    </Typography>
                    <Typography paragraph>
                        Semua hak cipta, merek dagang, dan hak kekayaan intelektual lainnya dalam aplikasi ini dimiliki oleh kami. Anda tidak boleh menggunakan bagian mana pun dari konten aplikasi ini tanpa izin tertulis dari kami.
                    </Typography>
                </Box>
                <Box my={2}>
                    <Typography variant="h6" component="h2">
                        5. Pembatasan Tanggung Jawab
                    </Typography>
                    <Typography paragraph>
                        Kami tidak bertanggung jawab atas kerusakan atau kerugian yang timbul dari penggunaan aplikasi ini. Aplikasi ini disediakan "sebagaimana adanya" dan "sebagaimana tersedia" tanpa jaminan apa pun, baik tersurat maupun tersirat.
                    </Typography>
                </Box>
                <Box my={2}>
                    <Typography variant="h6" component="h2">
                        6. Hukum yang Berlaku
                    </Typography>
                    <Typography paragraph>
                        Syarat dan ketentuan ini diatur oleh dan ditafsirkan sesuai dengan hukum negara Indonesia. Setiap sengketa yang timbul dari atau terkait dengan syarat dan ketentuan ini akan diselesaikan secara eksklusif di pengadilan negara Indonesia.
                    </Typography>
                </Box>
                <Box my={2}>
                    <Typography variant="h6" component="h2">
                        7. Kontak Kami
                    </Typography>
                    <Typography paragraph>
                        Jika Anda memiliki pertanyaan tentang syarat dan ketentuan ini, silakan hubungi kami di{' '}
                        <Link href="mailto:rikinurfalakhi.dev@gmail.com" className="text-blue-500 underline" color="inherit">
                            rikinurfalakhi.dev@gmail.com
                        </Link>{' '}
                        atau{' '}
                        <Link to="https://wa.me/6285649103100" className="text-blue-500 underline" target="_blank" rel="noopener noreferrer" color="inherit">
                            WhatsApp
                        </Link>.
                    </Typography>
                </Box>
                <Footer />
            </Container>
        </>
    );
};

export default SyaratDanKetentuan;
