import React, { useState } from 'react';
// import '../../App.css';
import './login.css'
import { useNavigate, Link } from 'react-router-dom';

const Login = ({ onLogin }) => {
    window.document.title = 'Login ~ Locator'

    const [number, setNumber] = useState('');
    const [error, setError] = useState(''); // State untuk menyimpan pesan kesalahan
    const [isChecked, setIsChecked] = useState(false); // State untuk menyimpan status kotak centang
    const navigate = useNavigate();

    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true);
        setTimeout(() => {
            setOpen(false);
        }, 5000); // Auto hide after 5 seconds
    };

    const handleLogin = () => {

        if (number === '') {
            setError('Anda harus mengisi ID Alat.');
            return;
        }
        if (!isChecked) {
            setError('Anda harus menyetujui persyaratan.');
            return;
        }

        // Validasi ID alat
        if (number === '358271' || number === '123') {
            console.log('betul');

            onLogin(true);
            localStorage.setItem('isLoggedIn', true);
            localStorage.setItem('deviceId', 358271);
            // Jika ID alat sesuai, navigasi ke halaman homepage
            navigate('/dashboard');
        } else if (number === '') {
            setError('ID alat tidak boleh kosong.');
        } else {
            console.log('salah');
            // Jika ID alat tidak sesuai, tampilkan pesan kesalahan
            setError('ID alat yang dimasukkan tidak terdaftar, mohon periksa kembali.');
        }
    };
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const redirectTerms = () => {
        navigate('/syarat-dan-ketentuan')

    }
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
                <h2 className="text-3xl font-bold text-center mb-6">Login Alat</h2>
                <form>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="number">
                            *Masukkan ID Alat
                        </label>
                        <p>358271</p>
                        <input
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="number"
                            type="text"
                            placeholder="ID Alat"
                            value={number}
                            onChange={(e) => {
                                const validNumber = e.target.value.replace(/[^0-9]/gi,'')
                                setNumber(validNumber);
                                setError(''); // Bersihkan pesan kesalahan saat mengubah input
                            }}
                        />
                        <div className={`text-red-500 text-sm mt-2 ${error ? 'visible' : 'invisible'}`}>
                            {error ? error : 'P'} {/* Tampilkan pesan error atau placeholder */}
                        </div>
                    </div>
                    <div className="mb-4 flex items-center">
                        <input
                            type="checkbox"
                            className="mr-2 leading-tight"
                            checked={isChecked}
                            onChange={(e) => {
                                setIsChecked(e.target.checked);
                                setError(''); // Bersihkan pesan kesalahan saat mengubah input
                            }}
                        />
                        <label style={{cursor: 'pointer'}} onClick={()=> setIsChecked(true)} className="text-gray-700 text-sm font-bold">
                            Saya menyetujui <Link to="/syarat-dan-ketentuan" className="text-blue-500 underline hover:text-blue-800">syarat dan ketentuan</Link>
                        </label>
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="button"
                            onClick={handleLogin}
                        >
                            Login
                        </button>
                      
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Login;
